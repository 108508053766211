export default function SkiPassTick(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.1547 20.8662C12.7614 20.8535 15.2578 19.7966 17.1015 17.925C18.9451 16.0535 19.9871 13.5186 20.0009 10.8712C19.9858 8.2233 18.9438 5.68813 17.1007 3.81527C15.2576 1.94242 12.7619 0.882832 10.1547 0.866211C7.54706 0.8811 5.05041 1.94013 3.20693 3.81336C1.36344 5.68659 0.32195 8.22277 0.308594 10.8712C0.322399 13.5186 1.36435 16.0535 3.20801 17.925C5.05168 19.7966 7.54809 20.8535 10.1547 20.8662ZM8.88295 15.9404C8.6917 15.9357 8.50402 15.8867 8.33423 15.7972C8.16445 15.7077 8.01704 15.5799 7.90326 15.4237L5.19557 12.1145C5.01397 11.9136 4.91324 11.6507 4.91331 11.3779C4.91212 11.249 4.93609 11.1211 4.98384 11.0016C5.03159 10.8822 5.10218 10.7735 5.19152 10.6819C5.28086 10.5903 5.38718 10.5176 5.50435 10.468C5.62151 10.4184 5.74719 10.3929 5.87413 10.3929C6.03458 10.3938 6.19254 10.4333 6.33513 10.508C6.47772 10.5827 6.60091 10.6906 6.69464 10.8229L8.85259 13.5395L13.0101 6.82538C13.0895 6.6642 13.2101 6.52761 13.3592 6.42985C13.5082 6.33209 13.6804 6.27673 13.8577 6.26955C13.9839 6.26304 14.1102 6.28188 14.2292 6.325C14.3482 6.36812 14.4577 6.43466 14.5514 6.52081C14.6451 6.60697 14.7211 6.71105 14.7751 6.82709C14.8291 6.94313 14.86 7.06884 14.8661 7.19704C14.8455 7.4549 14.7578 7.70254 14.6118 7.91454L9.83311 15.3954C9.73253 15.5599 9.59277 15.6961 9.42671 15.7914C9.26065 15.8866 9.07365 15.9379 8.88295 15.9404Z"
        fill="#6C63FF"
      />
    </svg>
  );
}
