import {
  getPendingStatus,
  obStatusCheck,
  obStatusCheckRepeat,
  postTransactionAction,
} from "api";
import axios from "axios";
import { BOG_URL } from "config";
import { useQueryString } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "utils/encryption";
import apple from "assets/images/icon/appstoreupdated.svg";
import googleIcon from "assets/images/icon/googleplayupdated.svg";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import TransactionCompleteAnim from "../../utils/pendincAnimation.json";

const Pending = () => {
  const { parseQuery: parsedQuery } = useQueryString();
  const { t } = useTranslation();

  const {
    acquiringTransactionId,
    ratingEnabled = "false",
    amount = "",
    receiverName = "",
    receiverId = "",
    receiverType = "",
    tipsEnabled = "false",
    token = "",
    currency = "",
    code = "",
    paymentProviderEnum = "",
    state = "",
    error = false,
  } = parsedQuery;
  const navigate = useNavigate();
  const formRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (state) {
      const fetchData = async () => {
        try {
          const {
            status,
            amount: amountOB,
            currency: currencyOB,
            ratingEnabled: ratingEnabledOB,
            tipsEnabled: tipsEnabledOB,
            receiverName: receiverNameOB,
            receiverId: receiverIdOB,
            beneficiaryType: receiverTypeOB,
            acquiringTransactionId: acquiringTransactionIdOB,
            integratorOrderId,
            genericId,
            showAwardedGiftComponent = "false",
          } = await obStatusCheck({
            state: state,
            code: code || null,
          });

          if (error) {
            navigate(
              `/payment-failed?acquiringTransactionId=${acquiringTransactionIdOB}&receiverId=${receiverIdOB}&receiverType=${receiverTypeOB}`
            );
            return;
          }

          const { redirectRequired, successRedirectUri, failRedirectUri } =
            await postTransactionAction(integratorOrderId);

          const url = `/success?acquiringTransactionId=${acquiringTransactionIdOB}&amount=${amountOB}&receiverType=${receiverTypeOB}&receiverName=${receiverNameOB}&ratingEnabled=${ratingEnabledOB}&tipsEnabled=${tipsEnabledOB}&receiverId=${receiverIdOB}&paymentProviderEnum=OB&paymentMethod=OB&currency=${currencyOB}&integratorOrderId=${integratorOrderId}&genericId=${genericId}&showAwardedGiftComponent=${showAwardedGiftComponent}`;

          if (redirectRequired && status != "PENDING") {
            if (status == "SUCCESSFUL" && !tipsEnabledOB && !ratingEnabledOB) {
              window.location.href = successRedirectUri;
              return;
            } else if (
              status == "SUCCESSFUL" &&
              (tipsEnabledOB || ratingEnabledOB)
            ) {
              navigate(url);
              return;
            } else if (status == "FAILED") {
              window.location.href = failRedirectUri;
              return;
            }
          } else if (status == "SUCCESSFUL") {
            navigate(url);
          } else if (status == "FAILED") {
            navigate(
              `/payment-failed?acquiringTransactionId=${acquiringTransactionIdOB}&receiverId=${receiverIdOB}&receiverType=${receiverTypeOB}`
            );
          } else if (status == "PENDING") {
            setTimeout(async () => {
              const { transactionStatus } = await obStatusCheckRepeat(
                acquiringTransactionIdOB
              );

              if (redirectRequired && transactionStatus != "PENDING") {
                if (
                  transactionStatus == "SUCCESSFUL" &&
                  !tipsEnabledOB &&
                  !ratingEnabledOB
                ) {
                  window.location.href = successRedirectUri;
                  return;
                } else if (
                  transactionStatus == "SUCCESSFUL" &&
                  (tipsEnabledOB || ratingEnabledOB)
                ) {
                  navigate(url);
                  return;
                } else if (transactionStatus == "FAILED") {
                  window.location.href = failRedirectUri;
                  return;
                }
              } else if (transactionStatus == "SUCCESSFUL") {
                navigate(url);
              } else if (transactionStatus == "FAILED") {
                navigate(
                  `/payment-failed?acquiringTransactionId=${acquiringTransactionIdOB}&receiverId=${receiverIdOB}&receiverType=${receiverTypeOB}`
                );
              } else if (transactionStatus == "PENDING") {
                setTimeout(async () => {
                  const { transactionStatus } = await obStatusCheckRepeat(
                    acquiringTransactionId
                  );

                  if (redirectRequired && transactionStatus != "PENDING") {
                    if (
                      transactionStatus == "SUCCESSFUL" &&
                      !tipsEnabledOB &&
                      !ratingEnabledOB
                    ) {
                      window.location.href = successRedirectUri;
                      return;
                    } else if (
                      transactionStatus == "SUCCESSFUL" &&
                      (tipsEnabledOB || ratingEnabledOB)
                    ) {
                      navigate(url);
                      return;
                    } else if (transactionStatus == "FAILED") {
                      window.location.href = failRedirectUri;
                      return;
                    }
                  } else if (transactionStatus == "SUCCESSFUL") {
                    navigate(url);
                  } else if (transactionStatus == "FAILED") {
                    navigate(
                      `/payment-failed?acquiringTransactionId=${acquiringTransactionIdOB}&receiverId=${receiverIdOB}&receiverType=${receiverTypeOB}`
                    );
                  }
                }, 5000);
              }
            }, 5000); // 5000 milliseconds = 5 seconds
          } else {
            console.log(" ");
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();

      return;
    }
  }, []);

  return (
    <div className="w-[100%] h-[100%] flex justify-around items-center flex-col relative">
      <div className="flex justify-center items-center flex-col gap-1  ">
        <div className="h-[100px] w-[100px] bg-[#FFA500] rounded-[50%] flex justify-center items-center">
          <Lottie
            animationData={TransactionCompleteAnim}
            style={{ width: "55px", height: "55px" }}
          />
        </div>
        <p className="text-[20px] text-[#170738] mt-[20px]">
          Payment is processing
        </p>
        <div style={{ transform: "translateY(-30px)", marginTop: "20px" }}>
          <p className="opacity-50 text-center whitespace-pre-line">
            It may take a few seconds
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center  ">
        <div className="flex items-center justify-center gap-[10px]">
          <a
            href="https://apps.apple.com/us/app/tip-app-mobile/id1579469134"
            target="_blank"
            rel="noreferrer"
          >
            <img src={apple} alt="#" width="158px" height="52px" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.tipapp"
            target="_blank"
            rel="noreferrer"
          >
            <img src={googleIcon} alt="#" width="158px" height="52px" />
          </a>
        </div>
        <div className="flex items-center justify-center w-full pb-10 self-end p-4">
          <button
            className="max-w-2xl  w-full inline-flex justify-center gap-2 p-4 text-[20px] text-[#6C63FF] font-[600] mt-4 rounded-2xl disabled:bg-gray-400 text-center items-center"
            style={{ height: "50px", borderRadius: "8px" }}
            onClick={() => {
              const currentURL = window.location.href;
              const url = new URL(currentURL);
              const newURL = `${url.origin}`;
              window.location.href = newURL;
            }}
          >
            {t("description.done")}
          </button>
        </div>
      </div>
      <form ref={formRef} method="post" action="" className="absolute">
        <input type="hidden" name="" ref={inputRef} value="" />
        <noscript>
          <center>
            <br />
            Please click the submit button below.
            <br />
            <input type="submit" value="Submit" />
          </center>
        </noscript>
      </form>
    </div>
  );
};

export default Pending;
