import ArrowRightIcon from "assets/icons/ArrowRightIcon";
import { Spinner } from "components/Spinner";
import {
  SET_PAYMENT_TYPE,
  SET_TOGGLE_PAYMENT_MODAL,
} from "features/bog/reducer/actions";

export const CryptoPaymentMethod = ({
  name,
  acquiringType,
  logo,
  // paymentType,
  dispatch,
  shouldHaveArrow = false,
  fn = false,
  // setCryptoAcquringType,
  isLoading,
  isLoadingOB,
}) => {
  return (
    <button
      disabled={isLoadingOB}
      className={
        "flex w-full p-[10px] justify-between  rounded-2xl [&_svg]:min-w-[40px] [&_button]:inline-flex [&_button]:items-center w- gap-[10px] items-center cursor-pointer border-[1px] border-[#6C63FF]/[20%] hover:border-[#6C63FF]/[50%] "
      }
      style={{ backgroundColor: "rgba(116, 116, 128, 0.08)" }}
      onClick={() => {
        if (fn) {
          fn();
        }
        // if (setCryptoAcquringType) {
        //   setCryptoAcquringType(acquiringType);
        // }
        if (dispatch) {
          dispatch({ type: SET_PAYMENT_TYPE, payload: acquiringType });
          dispatch({ type: SET_TOGGLE_PAYMENT_MODAL });
        }
      }}
    >
      <div className="flex items-center gap-[10px] ">
        <img src={logo} alt={"logo"} className="w-[40px] h-[40px] " />
        <p className="text-[16px] font-[400] leading-[19.09px] font-sans">
          {name}
        </p>
      </div>
      {shouldHaveArrow && !isLoading ? <ArrowRightIcon /> : null}
      {isLoading && <Spinner />}
    </button>
  );
};
