import ApplePayIcon from "assets/icons/ApplePayIcon";
import ArrowRightIcon from "assets/icons/ArrowRightIcon";
import CardIcon from "assets/icons/CardIcon";
import GooglePay from "assets/icons/GooglePay";
import VisaMasterIcon from "assets/icons/VisaMasterIcon";
import {
  AVAILABLE_PAYMENT_TYPES,
  INITIAL_STATE,
  PAYMENT_SERVICE_STATE,
  QR_TYPE,
  baseCardPaymentMethod,
  baseRequest,
  cardPaymentMethod,
  cardPaymentMethodTbc,
  cardPaymentMethodUfc,
  cardPaymentMethodUfc2,
} from "./config";
import paymentReducer from "./reducer/paymentReducer";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useQueryString } from "hooks";
import JSEncrypt from "jsencrypt";
import {
  SET_AMOUNT,
  SET_APPLE_PAY_STATE,
  SET_GPAY_STATE,
  SET_LOADING_PAYMENT,
  SET_PAYMENT_RESULT,
  SET_PAYMENT_TYPE,
  SET_PRODUCTS,
  SET_TOGGLE_INSTALLMENT_MODAL,
  SET_TOGGLE_PAYMENT_MODAL,
} from "./reducer/actions";
import {
  acceptTbcGooglePayPayment,
  acceptUfcGooglePayPayment,
  bogTransaction,
  checkOrderStatus,
  credoTransaction,
  eCommerceServiceInstance,
  getOBProviders,
  oBRequest,
  orderCityPay,
  orderCityPayStatus,
  orderCryptoPay,
  paymentServiceInstance,
  postTransactionAction,
  standardTransaction,
  startApplePayment,
  startTbcApplePayment,
  startTbcGooglePayment,
  startUfcApplePayment,
  startUfcGooglePayment,
  tbcTransaction,
} from "api";
import useSWR from "swr";
import {
  LAST_ACTIVE_ORDER,
  MERCHANT_PROFILE_URL,
  PRODUCT_URL,
  PROFILE_IMAGE_URL,
  PROFILE_IMAGE_URL_DEV,
  TRANSACTION_STATUS,
  USER_PROFILE_URL,
} from "helpers/fetcher/fetcher.config";
import {
  decrypt,
  decryptAES,
  encrypt as encryptJs,
  encryptAES,
  generateIV,
  parseBase64,
  generateKey,
  convertBase64,
} from "utils/encryption";
import { MERCHANT_NAME } from "config";
import { GPAY_MERCHANT_ID } from "config";
import { Trans, useTranslation } from "react-i18next";
import { acceptGooglePayPayment, startGooglePayPayment } from "./api";
import { Navigate, useNavigate, useLocation, Link } from "react-router-dom";
import { BOG_URL } from "config";
import axios from "axios";
import startApplePaySession from "features/apple/utils/startApplePaySession";
import EcommerceModal from "components/modals/EcommerceModal";
import UserNotFound from "components/UserNotFound";
import Loader from "components/Loader";
import PaymentFeedback from "components/PaymentFeedback";

import { ReactComponent as Hlogo } from "assets/images/logo.svg";
import QRCode from "qrcode.react";

import merchantIcon from "assets/images/icon/merchant-icon.svg";
import Svg from "../../assets/images/redesign/defaultAvatar.svg";
import APayIcon from "assets/icons/APayIcon";
import GpayIcon from "assets/icons/GpayIcon";
import OnlineBankIcon from "assets/icons/OnlineBankIcon";
import TbcBogIcon from "assets/icons/TbcBogIcon";
import BitCoinIcon from "assets/icons/BitCoinIcon";
import CryptosIcon from "assets/icons/CryptosIcon";
import { RATING_ALLOWED_STATUSES } from "constants";
import bog from "../../assets/images/bogIcon.svg";
import liberty from "../../assets/images/libertyIcon.svg";
import tbc from "../../assets/images/tbcIcon.svg";
import credo from "../../assets/images/credoIcon.svg";
import OBPaymentMethod from "components/OBPayment/OBPaymentMethod";
import startApplePaySessionTbc from "features/apple/utils/startApplePaySessionTbc";
import startApplePaySessionUfc from "features/apple/utils/startApplePaySessionUfc";
import CustomInput from "components/CustomInput";
import { isChrome, isSafari } from "react-device-detect";
import i18n from "../../i18n";
import ProductSelect from "components/ProductSelect/ProductSelect";
import ProductList from "components/ProductSelect/ProductList";
import useUserData from "hooks/useUserData";
import PlusIcon from "assets/icons/PlusIcon";
import MinusIcon from "assets/icons/MinusIcon";
import InstallmentModal from "components/Installment/InstallmentModal";
import Modal from "components/modals/TestModal";
import { useInstallmentProperties } from "./hooks/useInstallmentProperties";
import { useAcquringChoiceData } from "./hooks/useAcquringChoiceData";
import useConvertedAmountEcomm from "hooks/useConvertedAmountsEcom";
import formatter from "currency.js";
import useProductData from "hooks/useProductData";
import useLastActiveOrder from "hooks/useLastActiveOrder";
import lngIcon from "../../assets/images/lngIcon.svg";
import { changeLanguage } from "i18next";
import { CryptoPaymentMethod } from "components/CryptoPayment/CryptoPaymentMethod";

const MERCHANT_IDENTIFIER = "merchant.bog.keepz";
const MERCHANT_IDENTIFIER_TBC = "merchant.tbc.keepz";
const MERCHANT_IDENTIFIER_CREDO = "merchant.keepz.credo";

export const bankOptions = [
  {
    name: "Bank of Georgia",
    icon: bog,
    paymentType: "ob_bog",
    id: "2",
  },
  {
    name: "TBC Bank",
    icon: tbc,
    paymentType: "ob_tbc",
    id: "1",
  },
  {
    name: "Credo Bank",
    icon: credo,
    paymentType: "ob_credo",
    id: "3",
  },
  {
    name: "Liberty Bank",
    icon: liberty,
    paymentType: "ob_liberty",
    id: "4",
  },
];

const BogPaymentWeb = () => {
  const [
    {
      amount,
      loadingPayment,
      gpayState,
      applePayState,

      paymentResult,
      selectProducts,
      showInstallmentModal,
    },
    dispatch,
  ] = useReducer(paymentReducer, INITIAL_STATE);

  const { pathname, search } = useLocation();

  const { parseQuery, getStringFromQuery } = useQueryString();
  // const [commisonAmounts, setCommisionAmounts] = useState();
  const [convertedAmounts, setConvertedAmounts] = useState([]);
  const [availableApple, setAvailableApple] = useState(false);
  const [availableGoogle, setAvailableGoogle] = useState(false);
  const [linkIsCopied, setLinkIsCopied] = useState(false);
  const [acquiringType, setAcquiringType] = useState({});
  const [description, setDescription] = useState("");
  const [descError, setDescError] = useState("");
  const [formData, setFormData] = useState({
    payerName: "",
    payerPersonalNumber: "",
    additionalDescription: "",
  });
  const payerNameRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const payerPersonalNumberRef = useRef(null);
  const additionalDescriptionRef = useRef(null);
  const [isOBOpen, setIsOBOpen] = useState(false);
  const [isCryptoOpen, setIsCryptoOpen] = useState(false);
  const [isOBLoadingIndex, setIsOBLoadingIndex] = useState();
  const [isLoadingOB, setIsLoadingOB] = useState(false);
  const [ObProvider, setObProvider] = useState();
  const descriptionRef = useRef(null);
  const [personalNumberError, setPersonalNumberError] = useState(false);
  const [isNonResident, setIsNonResident] = useState(false);
  const [reqInputValues, setReqInputValues] = useState({});
  const [acquiringCurrencyReq, setAcquiringCurrencyReq] = useState();
  localStorage.removeItem("integratorOrderId");
  localStorage.removeItem("3dsCredoId");
  localStorage.removeItem("3dsTbcId");

  const {
    toUserId = "",
    status = "",
    transactionId = "",
    amount: requestedAmount = "",
    qrType = "",
    receiverId = "",
    receiverType = "USER",
    integratorId = "",
    integratorOrderId: integratorCheckOrderId,
    productId,
    currency: currnecyReq = "",
  } = parseQuery;
  localStorage.setItem("url", pathname + search);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleLangChange = () => {
    const newLang = language === "ge" ? "en" : "ge";
    const newQueryObj = { ...parseQuery, lng: newLang };
    const newQueryString = getStringFromQuery(newQueryObj);
    navigate({
      pathname,
      search: newQueryString,
    });
  };

  const navigate = useNavigate();

  const sanitizeUUID = (uuid) =>
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
      uuid
    )
      ? uuid
      : null;
  const receiverUserId = sanitizeUUID(toUserId || receiverId);
  localStorage.setItem("receiverUserId", receiverUserId);

  // const {
  //   data: {
  //     amountList: requestedAmount1 = [],
  //     currency: currencyProduct = "",
  //     currencyRate: currRateProduct,
  //     // acquiringCurrency: acquiringCurrencyProd,
  //   } = {},
  // } = useSWR(
  //   !!productId
  //     ? `${PRODUCT_URL}?ownerId=${receiverUserId}&identifier=${process.env.REACT_APP_INTEGRATOR_ID}&productId=${productId}&ownerType=${receiverType}`
  //     : null,
  //   async (url) => {
  //     try {
  //       const {
  //         data: {
  //           value: { encryptedData },
  //         },
  //       } = await paymentServiceInstance.get(url);
  //       return decrypt(encryptedData);
  //     } catch ({ response: { data: err } }) {
  //       dispatch({
  //         type: SET_PAYMENT_RESULT,
  //         payload: {
  //           show: true,
  //           type: err?.statusCode,
  //           group: err?.exceptionGroup,
  //         },
  //       });
  //     }
  //   }
  // );

  const { requestedAmount1, currencyProduct, currRateProduct } = useProductData(
    productId,
    receiverUserId,
    receiverType,
    dispatch
  );

  // const {
  //   data: {
  //     integratorOrderId = integratorCheckOrderId,
  //     currency: currencyCheck = "",
  //     prefilledValues = {},
  //     // acquiringCurrency: acquiringCurrencyCheck,
  //   } = {},
  // } = useSWR(
  //   useMemo(() => {
  //     if (integratorId.length && qrType !== QR_TYPE.REQUEST) {
  //       const encrypt = new JSEncrypt();
  //       encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
  //       const encryptedData = encodeURIComponent(
  //         encrypt.encrypt(
  //           JSON.stringify({
  //             integratorId: integratorId,
  //             receiverId: receiverUserId,
  //           })
  //         )
  //       );

  //       return `${LAST_ACTIVE_ORDER}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}&encryptedData=${encryptedData}`;
  //     } else {
  //       return null;
  //     }
  //   }, [integratorId, qrType, receiverUserId]),
  //   async (url) => {
  //     try {
  //       const {
  //         data: {
  //           value: { encryptedData },
  //         },
  //       } = await eCommerceServiceInstance.get(url);
  //       const encrypt = new JSEncrypt();
  //       encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
  //       const decryptedData = JSON.parse(encrypt.decrypt(encryptedData));
  //       const {
  //         amount: orderedAmount,
  //         prefilledValues: {
  //           purpose = "",
  //           personalNumber = "",
  //           personalNumberOrPassport = "",
  //           payerName = "",
  //           isForeign = false,
  //         },
  //       } = decryptedData;
  //       dispatch({ type: SET_AMOUNT, payload: orderedAmount });
  //       setFormData({
  //         payerName: payerName,
  //         payerPersonalNumber: personalNumber || personalNumberOrPassport,
  //         additionalDescription: purpose,
  //       });
  //       setIsNonResident(isForeign);

  //       return decryptedData;
  //     } catch ({ response: { data: err } }) {
  //       dispatch({
  //         type: SET_PAYMENT_RESULT,
  //         payload: {
  //           show: true,
  //           type: err?.statusCode,
  //           group: err?.exceptionGroup,
  //         },
  //       });
  //     }
  //   }
  // );

  const { integratorOrderId, currencyCheck, prefilledValues, qrCheckError } =
    useLastActiveOrder(
      integratorId,
      qrType,
      receiverUserId,
      dispatch,
      setFormData,
      setIsNonResident,
      integratorCheckOrderId
    );

  const {
    data: { value: { result: { status: transactionStatus } = {} } = {} } = {},
  } = useSWR(
    transactionId
      ? `${TRANSACTION_STATUS}?providerId=${transactionId}&fromMobile=false`
      : null
  );

  const { data, userError = {} } = useUserData(receiverType, receiverUserId);

  const {
    data: merchant,
    error: { response: { data: merchantError } = {} } = {},
  } = useSWR(
    receiverType === "BRANCH"
      ? `${MERCHANT_PROFILE_URL}/${receiverUserId}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    async (url) => {
      const {
        data: {
          value: { encryptedData, aes, encryptedKeys },
        },
      } = await paymentServiceInstance.get(url);
      const encrypteSecretKey = decrypt(encryptedKeys, {
        shouldParse: false,
      });
      const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
      const secretKey = parseBase64(base64SecretKey);
      const iv = parseBase64(base64IV);
      const encrypted = decryptAES(encryptedData, secretKey, iv);
      return encrypted;
    }
  );

  const { installmentProperties } = useInstallmentProperties();

  const { data: { rate = "" } = {} } = useSWR(
    integratorOrderId || integratorCheckOrderId
      ? `/api/integrator/order/rate/encrypted?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    async (url) => {
      try {
        const data = {
          integratorId: integratorId,
          integratorOrderId: integratorOrderId || integratorCheckOrderId,
        };
        const iv = generateIV();
        const secretKey = generateKey();
        const ivBase64 = convertBase64(iv);
        const secretKeyBase64 = convertBase64(secretKey);
        const base64 = `${secretKeyBase64}.${ivBase64}`;
        const encryptedBase64 = encryptJs(base64, {
          stringify: false,
          shouldEncode: true,
        });
        const encrypteData = encryptAES(data, secretKey, iv, {
          shouldEncode: true,
        });
        const {
          data: {
            value: { encryptedData, aes, encryptedKeys },
          },
        } = await eCommerceServiceInstance.get(
          url +
            `&encryptedData=${encrypteData}&encryptedKeys=${encryptedBase64}`
        );
        const encrypteSecretKey = decrypt(encryptedKeys, {
          shouldParse: false,
        });
        const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
        const secretkey = parseBase64(base64SecretKey);
        const IV = parseBase64(base64IV);
        const encrypted = decryptAES(encryptedData, secretkey, IV);
        return encrypted;
      } catch (error) {
        console.log("error");
      }
    }
  );

  const validIds = new Set([
    "9c6035da-77d3-42fa-9b5c-6d35a8a786f2",
    "924a8173-c37f-4c43-a2d0-ed37483ab8f3",
    "ca3f6a9e-d6c5-4b65-ba9e-4db7c39b2c09",
    "fda10269-8273-4579-86e6-c84032e44921",
    "9f1e3894-c4b9-4091-b109-116b2f997196",
    "8867b5a2-b521-4a95-bacf-ade2afabc93e",
    "0c59610c-d60d-4715-8964-f80e08d28cfb",
    "cbc31377-ae2e-41e3-9ffa-cbffdb22a397",
    "644cf8c0-27a3-4db5-ba03-7699891fe631",
    "c9e5d1bf-8ac6-4b12-91e5-f3671f7fbd12",
    "038f40ec-de81-49eb-9e9d-86c3deb2c28c",
    "bdfd921c-9311-4ef0-99f2-d939b8a16d1c",
    "96c19b31-f1a5-4c90-b13f-faf501478173",
    "54b13747-8a4a-441c-a21c-e830e9b56bf2",
  ]);

  const hideQR = (id) => validIds.has(id);

  const curr =
    selectProducts?.currency ||
    currencyCheck ||
    currencyProduct ||
    currnecyReq ||
    (data || merchant)?.currency ||
    "GEL";

  const currRate =
    selectProducts?.currencyRate ||
    rate ||
    currRateProduct ||
    (data || merchant)?.currencyRate ||
    0;

  // const acquiringCurrency =
  //   acquiringCurrencyCheck ||
  //   acquiringCurrencyProd ||
  //   acquiringCurrencyReq ||
  //   "GEL";

  function convertToEur(amount) {
    return Math.ceil((Number(amount) * currRate * 100).toFixed(2)) / 100;
  }

  const isMerchant =
    (data?.userType || merchant?.userType || receiverType) === "BRANCH";
  const isBusiness =
    (data?.userType || merchant?.userType || receiverType) === "BUSINESS";

  const getImage = () => {
    const img =
      window.location.hostname == "www.web.appdev.keepz.me"
        ? PROFILE_IMAGE_URL_DEV
        : PROFILE_IMAGE_URL;
    if (merchant?.imagePath || data?.imagePath) {
      return img + (merchant?.imagePath || data?.imagePath);
    } else if (isMerchant || isBusiness) {
      return merchantIcon;
    } else {
      return Svg;
    }
  };

  const { data: commisonUsers, error: commisonUserError } = useSWR(
    receiverType === "USER"
      ? `${USER_PROFILE_URL}/${receiverUserId}/commissions?identifier=${
          process.env.REACT_APP_INTEGRATOR_ID
        }${integratorId ? `&integratorId=${integratorId}` : ""}`
      : null,
    async (url) => {
      const {
        data: {
          value: { encryptedData, aes, encryptedKeys },
        },
      } = await paymentServiceInstance.get(url);
      const encrypteSecretKey = decrypt(encryptedKeys, {
        shouldParse: false,
      });
      const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
      const secretkey = parseBase64(base64SecretKey);
      const IV = parseBase64(base64IV);
      const encrypted = decryptAES(encryptedData, secretkey, IV);
      return encrypted;
    }
  );

  const { data: commisonMerchant, error: commisonMerhcantError } = useSWR(
    receiverType === "BRANCH"
      ? `${MERCHANT_PROFILE_URL}/${receiverUserId}/commission/commissions?identifier=${
          process.env.REACT_APP_INTEGRATOR_ID
        }${integratorId ? `&integratorId=${integratorId}` : ""}`
      : null,
    async (url) => {
      const {
        data: {
          value: { encryptedData, aes, encryptedKeys },
        },
      } = await paymentServiceInstance.get(url);
      const encrypteSecretKey = decrypt(encryptedKeys, {
        shouldParse: false,
      });
      const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
      const secretkey = parseBase64(base64SecretKey);
      const IV = parseBase64(base64IV);
      const encrypted = decryptAES(encryptedData, secretkey, IV);
      return encrypted;
    }
  );

  const rangedAppleCommission =
    commisonUsers?.filter((item) => item.acquiringType == "APPLE_PAY") ||
    commisonMerchant?.filter((item) => item.acquiringType === "APPLE_PAY");

  const senderCommissionPercentApple = rangedAppleCommission?.find(
    (item) => item.startAmount <= amount
  );

  const rangedGoogleCommission =
    commisonUsers?.filter((item) => item.acquiringType == "GOOGLE_PAY") ||
    commisonMerchant?.filter((item) => item.acquiringType === "GOOGLE_PAY");

  const senderCommissionPercentGoogle = rangedGoogleCommission?.find(
    (item) => item.startAmount <= amount
  );

  const rangedStandardCommission =
    commisonUsers?.filter((item) => item.acquiringType == "STANDARD") ||
    commisonMerchant?.filter((item) => item.acquiringType === "STANDARD");

  const senderCommissionPercentStandard = rangedStandardCommission?.find(
    (item) => item.startAmount <= amount
  );

  const rangedCryptoCommission =
    commisonUsers?.filter((item) => item.acquiringType == "CRYPTO") ||
    commisonMerchant?.filter((item) => item.acquiringType === "CRYPTO");

  const senderCommissionPercentCrypto = rangedCryptoCommission?.find(
    (item) => item.startAmount <= amount
  );

  const rangedOBCommission =
    commisonUsers?.filter((item) => item.acquiringType == "OB") ||
    commisonMerchant?.filter((item) => item.acquiringType === "OB");

  const senderCommissionPercentOB = rangedOBCommission?.find(
    (item) => item.startAmount <= amount
  );

  const rangedInstallmentCommission =
    commisonUsers?.filter((item) => item.acquiringType == "INSTALLMENT") ||
    commisonMerchant?.filter((item) => item.acquiringType === "INSTALLMENT");

  const senderCommissionPercentInstallmet = rangedInstallmentCommission?.find(
    (item) => item.startAmount <= amount
  );

  function checkIfValuesAreEqual() {
    return (
      senderCommissionPercentApple?.senderCommission ===
        senderCommissionPercentGoogle?.senderCommission &&
      senderCommissionPercentGoogle ===
        senderCommissionPercentCrypto?.senderCommission &&
      senderCommissionPercentCrypto?.senderCommission ===
        senderCommissionPercentStandard?.senderCommission
    );
  }

  const senderCommissionBool = commisonUsers || commisonMerchant;
  const totalWithCommision = useMemo(() => {
    if (!senderCommissionBool || !amount) {
      return;
    }

    let comissionApple;
    let comissionRoundedApple;
    if (senderCommissionPercentApple?.rateType == "FIXED") {
      comissionApple = null;
      comissionRoundedApple = senderCommissionPercentApple?.senderCommission;
    } else {
      comissionApple =
        (amount * senderCommissionPercentApple?.senderCommission) / 100;
      comissionRoundedApple =
        Math.ceil((comissionApple * 100).toFixed(2)) / 100;
    }

    let comissionGoogle;
    let comissionRoundedGoogle;
    if (senderCommissionPercentGoogle?.rateType == "FIXED") {
      comissionGoogle = null;
      comissionRoundedGoogle = senderCommissionPercentGoogle?.senderCommission;
    } else {
      comissionGoogle =
        (amount * senderCommissionPercentGoogle?.senderCommission) / 100;
      comissionRoundedGoogle =
        Math.ceil((comissionGoogle * 100).toFixed(2)) / 100;
    }

    let comissionStandard;
    let comissionRoundedStandard;
    if (senderCommissionPercentStandard?.rateType == "FIXED") {
      comissionStandard = null;
      comissionRoundedStandard =
        senderCommissionPercentStandard?.senderCommission;
    } else {
      comissionStandard =
        (amount * senderCommissionPercentStandard?.senderCommission) / 100;
      comissionRoundedStandard =
        Math.ceil((comissionStandard * 100).toFixed(2)) / 100;
    }
    let comissionCrypto;
    let comissionRoundedCrypto;
    if (senderCommissionPercentCrypto?.rateType == "FIXED") {
      comissionCrypto = null;
      comissionRoundedCrypto = senderCommissionPercentCrypto?.senderCommission;
    } else {
      comissionCrypto =
        (amount * senderCommissionPercentCrypto?.senderCommission) / 100;
      comissionRoundedCrypto =
        Math.ceil((comissionCrypto * 100).toFixed(2)) / 100;
    }
    let comissionOB;
    let comissionRoundedOB;
    if (senderCommissionPercentOB?.rateType == "FIXED") {
      comissionOB = null;
      comissionRoundedOB = senderCommissionPercentOB?.senderCommission;
    } else {
      comissionOB =
        (amount * senderCommissionPercentOB?.senderCommission) / 100;
      comissionRoundedOB = Math.ceil((comissionOB * 100).toFixed(2)) / 100;
    }
    let comissionInsatllment;
    let comissionRoundedInstallment;
    if (senderCommissionPercentInstallmet?.rateType == "FIXED") {
      comissionInsatllment = null;
      comissionRoundedInstallment =
        senderCommissionPercentInstallmet?.senderCommission;
    } else {
      comissionInsatllment =
        (amount * senderCommissionPercentInstallmet?.senderCommission) / 100;
      comissionRoundedInstallment =
        Math.ceil((comissionInsatllment * 100).toFixed(2)) / 100;
    }
    return {
      totalApple: (Number(amount) + Number(comissionRoundedApple)).toFixed(2),
      comissionApple: parseFloat(comissionRoundedApple),
      totalGoogle: (Number(amount) + Number(comissionRoundedGoogle)).toFixed(2),
      comissionGoogle: parseFloat(comissionRoundedGoogle),
      totalStandard: (
        Number(amount) + Number(comissionRoundedStandard)
      ).toFixed(2),
      comissionStandard: parseFloat(comissionRoundedStandard),
      totalCrypto: (Number(amount) + Number(comissionRoundedCrypto)).toFixed(2),
      comissionCrypto: parseFloat(comissionRoundedCrypto),
      totalOB: (Number(amount) + Number(comissionRoundedOB)).toFixed(2),
      comissionOB: parseFloat(comissionRoundedOB),
      totalInsallment: (
        Number(amount) + Number(comissionRoundedInstallment)
      ).toFixed(2),
      comissionInsatllment: parseFloat(comissionRoundedInstallment),
    };
  }, [
    amount,
    senderCommissionPercentApple,
    senderCommissionPercentCrypto,
    senderCommissionPercentGoogle,
    senderCommissionPercentStandard,
    senderCommissionBool,
  ]);

  const gpayRef = useRef();
  const inputRef = useRef();
  const inputRefUfc = useRef();
  const formRef = useRef();
  const formRefUfc = useRef();
  const fixedAmount = useMemo(
    () =>
      amount
        ? Number(String(amount).replace(/,/g, ".")).toFixed(2)
        : requestedAmount ||
          requestedAmount1[0] ||
          data?.amountForDefaultQR ||
          merchant?.amountForDefaultQR,
    [amount, requestedAmount, requestedAmount1[0], data, merchant]
  );

  const changePaymentType = (type) => {
    dispatch({ type: SET_PAYMENT_TYPE, payload: type });
    dispatch({ type: SET_TOGGLE_PAYMENT_MODAL });
  };

  const handlePaymentResulClose = (type) => {
    dispatch({ type: SET_LOADING_PAYMENT, payload: false });
    if (status || transactionId) {
      navigate({
        pathname,
        search: window.location.search.replace("&status=FAIL", ""),
      });
    }
    dispatch({
      type: SET_PAYMENT_RESULT,
      payload: {
        show: false,
        type: type,
      },
    });
  };

  // const standardPriorityArray =
  //   acquiringType?.availableAcquiringTypes?.STANDARD?.sort(
  //     (a, b) => a.priority - b.priority
  //   );
  // const applePriorityArray =
  //   acquiringType?.availableAcquiringTypes?.APPLE_PAY?.sort(
  //     (a, b) => a.priority - b.priority
  //   );

  // const googlePayPriorityArray =
  //   acquiringType?.availableAcquiringTypes?.GOOGLE_PAY?.sort(
  //     (a, b) => a.priority - b.priority
  //   );

  const standardPriorityArray = useMemo(() => {
    return acquiringType?.availableAcquiringTypes?.STANDARD?.sort(
      (a, b) => a.priority - b.priority
    );
  }, [acquiringType]);

  const applePriorityArray = useMemo(() => {
    return acquiringType?.availableAcquiringTypes?.APPLE_PAY?.sort(
      (a, b) => a.priority - b.priority
    );
  }, [acquiringType]);

  const googlePayPriorityArray = useMemo(() => {
    return acquiringType?.availableAcquiringTypes?.GOOGLE_PAY?.sort(
      (a, b) => a.priority - b.priority
    );
  }, [acquiringType]);

  function checkInputs() {
    setDescError(false);
    setPersonalNumberError(false);
    if (
      (data || merchant)?.descriptionRequired &&
      !description.length &&
      descriptionRef?.current
    ) {
      setDescError(true);
      descriptionRef.current.focus();
      return false;
    }
    if (
      merchant?.payerNameRequired &&
      !formData?.payerName?.length &&
      payerNameRef?.current
    ) {
      payerNameRef.current.focus();
      return false;
    }
    if (
      merchant?.personalNumberRequired &&
      !formData?.payerPersonalNumber?.length &&
      payerPersonalNumberRef?.current
    ) {
      payerPersonalNumberRef.current.focus();
      return false;
    }
    if (
      merchant?.purposeRequired &&
      !formData?.additionalDescription?.length &&
      additionalDescriptionRef?.current
    ) {
      additionalDescriptionRef.current.focus();
      return false;
    }
    if (
      merchant?.personalNumberRequired &&
      formData?.payerPersonalNumber?.length != 11 &&
      !isNonResident
    ) {
      setPersonalNumberError(true);
      return false;
    }
    if (
      merchant?.personalNumberOrPassportRequired &&
      !formData?.payerPersonalNumber?.length &&
      payerPersonalNumberRef?.current
    ) {
      payerPersonalNumberRef.current.focus();
      return false;
    }

    return true;
  }

  async function handleOBPayment(providerId, index) {
    if (!checkInputs()) return;
    try {
      setIsOBLoadingIndex(index);
      setIsLoadingOB(true);

      const payload = {
        amount: fixedAmount,
        receiverId: receiverUserId,
        receiverType,
        openBankingProviderId: providerId,
        qrType:
          merchant?.acquiringDetails?.acquiringDetailsType == "PRODUCT" &&
          qrType == "DEFAULT"
            ? "CUSTOM"
            : qrType,
        currency: curr || "GEL",
        fromMobile: false,
        senderCommissionAmount: commisonAmounts?.openBankingCommision,
        ...(integratorId && { integratorId }),
        ...((integratorOrderId || productId || selectProducts?.id) && {
          integratorOrderId:
            selectProducts?.id || integratorOrderId || productId,
        }),
        ...(!!description?.length && { description }),
        ...(!!formData?.payerName?.length && {
          payerName: formData?.payerName,
        }),
        ...(!!formData?.additionalDescription?.length && {
          purpose: formData?.additionalDescription,
        }),
        ...(!!formData?.payerPersonalNumber?.length && {
          personalNumber: formData?.payerPersonalNumber,
        }),
      };
      const { scaOauthUrl, id, codeVerifier } = await oBRequest(payload);

      window.location.href = scaOauthUrl;
      setIsLoadingOB(false);
      setIsOBLoadingIndex("");
    } catch ({ response: { data: err } }) {
      setIsOBLoadingIndex("");
      setIsLoadingOB(false);

      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: err?.statusCode,
          group: err?.exceptionGroup,
        },
      });
    }
  }

  function handleCryptoPayment(acquiringType, index) {
    if (!checkInputs()) return;

    setIsOBLoadingIndex(index);
    setIsLoadingOB(true);
    localStorage.setItem("acquiring", "bog");
    localStorage.setItem("paymentMethod", "card");
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
    const encryptedData = encrypt.encrypt(
      JSON.stringify({
        amount: Number(fixedAmount),
        toBenefitId: receiverUserId,
        beneficialType: receiverType,
        currency: curr || "GEL",
        senderCommissionAmount: commisonAmounts?.cityPayCommision,
        ...(integratorId && { integratorId }),
        ...((integratorOrderId || productId || selectProducts?.id) && {
          integratorOrderId:
            selectProducts?.id || integratorOrderId || productId,
        }),
        ...(!!description?.length && { description }),
        ...(!!formData?.payerName?.length && {
          payerName: formData?.payerName,
        }),
        ...(!!formData?.additionalDescription?.length && {
          purpose: formData?.additionalDescription,
        }),
        ...(!!formData?.payerPersonalNumber?.length && {
          personalNumber: formData?.payerPersonalNumber,
        }),
        qrType:
          merchant?.acquiringDetails?.acquiringDetailsType == "PRODUCT" &&
          qrType == "DEFAULT"
            ? "CUSTOM"
            : qrType,
        acquiringType: acquiringType,
      })
    );
    orderCryptoPay({
      encryptedData: encryptedData,
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
    })
      .then(({ encryptedData }) => {
        encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
        const { payment_url } = JSON.parse(encrypt.decrypt(encryptedData));
        localStorage.setItem("binance", payment_url);
        window.open(payment_url, "_self");
        setIsLoadingOB(false);
        setIsOBLoadingIndex("");
      })
      .catch(async ({ response: { data: err } }) => {
        setIsLoadingOB(false);
        setIsOBLoadingIndex("");
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.statusCode,
            group: err?.exceptionGroup,
          },
        });
      })
      .finally(() => {
        setIsLoadingOB(false);
        setIsOBLoadingIndex("");
      });
  }

  function handleApplePayment() {
    if (!navigator.onLine) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "INTERNET",
        },
      });
      return;
    }

    if (!window.ApplePaySession) {
      setAvailableApple(true);
      return;
    }

    if (!fixedAmount) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
        },
      });
      return;
    }

    if (!checkInputs()) return;
    localStorage.setItem("paymentMethod", "applePay");

    if (fixedAmount) {
      dispatch({ type: SET_LOADING_PAYMENT, payload: true });
    }

    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
    const lang = language === "ge" ? "KA" : "EN";
    const startApplePayload = {
      convertedAmount:
        curr == "EUR" || curr == "USD"
          ? convertToEur(fixedAmount)
          : Number(fixedAmount),
      amount: Number(fixedAmount),
      receiverType: receiverType || "User",
      senderCommissionAmount: commisonAmounts?.appleCommision,
      ...(integratorId && { integratorId }),
      ...((integratorOrderId || productId || selectProducts?.id) && {
        integratorOrderId: selectProducts?.id || integratorOrderId || productId,
      }),
      ...(!!description?.length && { description }),
      ...(!!formData?.payerName?.length && { payerName: formData?.payerName }),
      ...(!!formData?.additionalDescription?.length && {
        purpose: formData?.additionalDescription,
      }),
      ...(!!formData?.payerPersonalNumber?.length && {
        personalNumber: formData?.payerPersonalNumber,
      }),
      qrType:
        merchant?.acquiringDetails?.acquiringDetailsType == "PRODUCT" &&
        qrType == "DEFAULT"
          ? "CUSTOM"
          : qrType,
      currency: curr || "GEL",
      fromMobile: false,
      receiverId: receiverUserId,
      lang,
    };

    if (applePriorityArray[0]?.acquiringName == "BOG") {
      localStorage.setItem("acquiring", "bog");
      try {
        const result = encrypt.encrypt(JSON.stringify(startApplePayload));
        const transactionIdPromise = startApplePayment({
          encryptedData: result,
          identifier: process.env.REACT_APP_INTEGRATOR_ID,
        });
        startApplePaySession(
          {
            // amount:
            //   curr == "EUR" || curr == "USD"
            //     ? totalWithCommision?.totalApple
            //       ? convertToEur(Number(fixedAmount)) +
            //         convertToEur(Number(totalWithCommision?.comissionApple))
            //       : convertToEur(Number(fixedAmount))
            //     : Number(totalWithCommision?.totalApple || fixedAmount),
            amount:
              curr == "EUR" || curr == "USD"
                ? Number(
                    formatter(convertedAmounts[0]).add(convertedAmounts[3])
                  )
                : Number(
                    formatter(commisonAmounts?.appleCommision).add(fixedAmount)
                  ),
            transactionIdPromise: transactionIdPromise,
            integratorOrderId: integratorOrderId || integratorCheckOrderId,
            integratorId: integratorId,
            dispatch,
            qrType: qrType,
          },
          (res, genericId) => {
            if (res?.status === 0) {
              const url = `/success?acquiringTransactionId=${
                res.transactionId
              }&amount=${fixedAmount}&receiverType=${receiverType}&receiverName=${encodeURIComponent(
                (merchant || data)?.name
              )}&ratingEnabled=${!!(
                merchant?.reviewRequired || data?.reviewRequired
              )}&tipsEnabled=${!!(
                merchant?.tipsEnabled || data?.tipsEnabled
              )}&receiverId=${receiverUserId}&paymentProviderEnum=BOG&paymentMethod=apple&currency=${
                curr || "GEL"
              }&currencyRate=${
                curr == "EUR" || curr == "USD" ? currRate : "0"
              }&genericId=${genericId}&showAwardedGiftComponent=${!!(
                merchant?.showAwardedGiftComponent ||
                data?.showAwardedGiftComponent
              )}`;
              navigate(url);
            } else {
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
              // async function failRedirct() {
              //   if (integratorCheckOrderId || integratorOrderId) {
              //     const { redirectRequired, failRedirectUri } =
              //       await postTransactionAction(integratorOrderId);
              //     if (redirectRequired && failRedirectUri) {
              //       window.location.href = failRedirectUri;
              //       return;
              //     }
              //   }
              // }
              // failRedirct();
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: res?.status?.response?.data?.statusCode,
                  group: res?.status?.response?.data?.exceptionGroup,
                },
              });
            }
          }
        );
      } catch (err) {
        console.log("err");
        // async function failRedirct() {
        //   if (integratorCheckOrderId || integratorOrderId) {
        //     const { redirectRequired, failRedirectUri } =
        //       await postTransactionAction(integratorOrderId);
        //     if (redirectRequired && failRedirectUri) {
        //       window.location.href = failRedirectUri;
        //       return;
        //     }
        //   }
        // }
        // failRedirct();
        // dispatch({
        //   type: SET_PAYMENT_RESULT,
        //   payload: {
        //     show: true,
        //     type: err?.response?.data?.statusCode,
        //     group: err?.response?.data?.exceptionGroup,
        //   },
        // });
      }
    } else if (applePriorityArray[0]?.acquiringName == "TBC") {
      localStorage.setItem("acquiring", "tbc");
      try {
        const transactionIdPromise = startTbcApplePayment(startApplePayload);
        startApplePaySessionTbc(
          {
            // amount:
            //   curr == "EUR" || curr == "USD"
            //     ? totalWithCommision?.totalApple
            //       ? convertToEur(Number(fixedAmount)) +
            //         convertToEur(Number(totalWithCommision?.comissionApple))
            //       : convertToEur(Number(fixedAmount))
            //     : Number(totalWithCommision?.totalApple || fixedAmount),
            amount:
              curr == "EUR" || curr == "USD"
                ? Number(
                    formatter(convertedAmounts[0]).add(convertedAmounts[3])
                  )
                : Number(
                    formatter(commisonAmounts?.appleCommision).add(fixedAmount)
                  ),
            transactionIdPromise: transactionIdPromise,
            integratorOrderId: integratorOrderId || integratorCheckOrderId,
            integratorId: integratorId,
            dispatch,
            qrType: qrType,
          },
          (res, genericId) => {
            if (res?.status === 0) {
              const url = `/success?acquiringTransactionId=${
                res.transactionId
              }&amount=${fixedAmount}&receiverType=${receiverType}&receiverName=${encodeURIComponent(
                (merchant || data)?.name
              )}&ratingEnabled=${!!(
                merchant?.reviewRequired || data?.reviewRequired
              )}&tipsEnabled=${!!(
                merchant?.tipsEnabled || data?.tipsEnabled
              )}&currency=${
                curr || "GEL"
              }&receiverId=${receiverUserId}&paymentProviderEnum=TBC&paymentMethod=apple&currencyRate=${
                curr == "EUR" || curr == "USD" ? currRate : "0"
              }&genericId=${genericId}&showAwardedGiftComponent=${!!(
                merchant?.showAwardedGiftComponent ||
                data?.showAwardedGiftComponent
              )}`;
              navigate(url);
            } else {
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
              // async function failRedirct() {
              //   if (integratorCheckOrderId || integratorOrderId) {
              //     const { redirectRequired, failRedirectUri } =
              //       await postTransactionAction(integratorOrderId);
              //     if (redirectRequired && failRedirectUri) {
              //       window.location.href = failRedirectUri;
              //       return;
              //     }
              //   }
              // }
              // failRedirct();
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: res?.status?.response?.data?.statusCode,
                  group: res?.status?.response?.data?.exceptionGroup,
                },
              });
            }
          }
        );
      } catch (err) {
        console.log("err");
        // async function failRedirct() {
        //   if (integratorCheckOrderId || integratorOrderId) {
        //     const { redirectRequired, failRedirectUri } =
        //       await postTransactionAction(integratorOrderId);
        //     if (redirectRequired && failRedirectUri) {
        //       window.location.href = failRedirectUri;
        //       return;
        //     }
        //   }
        // }
        // failRedirct();
        // dispatch({
        //   type: SET_PAYMENT_RESULT,
        //   payload: {
        //     show: true,
        //     type: err?.status?.response?.data?.statusCode,
        //     group: err?.status?.response?.data?.exceptionGroup,
        //   },
        // });
      }
    } else if (applePriorityArray[0]?.acquiringName == "CREDO") {
      try {
        const transactionIdPromise = startUfcApplePayment(startApplePayload);
        startApplePaySessionUfc(
          {
            // amount:
            //   curr == "EUR" || curr == "USD"
            //     ? totalWithCommision?.comissionApple
            //       ? convertToEur(Number(fixedAmount)) +
            //         convertToEur(Number(totalWithCommision?.comissionApple))
            //       : convertToEur(Number(fixedAmount))
            //     : Number(totalWithCommision?.totalApple || fixedAmount),
            amount:
              curr == "EUR" || curr == "USD"
                ? Number(
                    formatter(convertedAmounts[0]).add(convertedAmounts[3])
                  )
                : Number(
                    formatter(commisonAmounts?.appleCommision).add(fixedAmount)
                  ),
            transactionIdPromise: transactionIdPromise,
            integratorOrderId: integratorOrderId || integratorCheckOrderId,
            integratorId: integratorId,
            dispatch,
            qrType: qrType,
            urlCredo: applePriorityArray[0]?.url,
          },
          (res, genericId) => {
            if (res?.status === 0) {
              const url = `/success?acquiringTransactionId=${
                res.transactionId
              }&amount=${fixedAmount}&receiverType=${receiverType}&receiverName=${encodeURIComponent(
                (merchant || data)?.name
              )}&ratingEnabled=${!!(
                merchant?.reviewRequired || data?.reviewRequired
              )}&tipsEnabled=${!!(
                merchant?.tipsEnabled || data?.tipsEnabled
              )}&receiverId=${receiverUserId}&paymentProviderEnum=CREDO&paymentMethod=apple&currency=${
                curr || "GEL"
              }&currencyRate=${
                curr == "EUR" || curr == "USD" ? currRate : "0"
              }&genericId=${genericId}&showAwardedGiftComponent=${!!(
                merchant?.showAwardedGiftComponent ||
                data?.showAwardedGiftComponent
              )}`;
              navigate(url);
            } else {
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
              // async function failRedirct() {
              //   if (integratorCheckOrderId || integratorOrderId) {
              //     const { redirectRequired, failRedirectUri } =
              //       await postTransactionAction(integratorOrderId);
              //     if (redirectRequired && failRedirectUri) {
              //       window.location.href = failRedirectUri;
              //       return;
              //     }
              //   }
              // }
              // failRedirct();
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: res?.status?.response?.data?.statusCode,
                  group: res?.status?.response?.data?.exceptionGroup,
                },
              });
            }
          }
        );
      } catch (err) {
        console.log("err");
        // async function failRedirct() {
        //   if (integratorCheckOrderId || integratorOrderId) {
        //     const { redirectRequired, failRedirectUri } =
        //       await postTransactionAction(integratorOrderId);
        //     if (redirectRequired && failRedirectUri) {
        //       window.location.href = failRedirectUri;
        //       return;
        //     }
        //   }
        // }
        // failRedirct();
        // dispatch({
        //   type: SET_PAYMENT_RESULT,
        //   payload: {
        //     show: true,
        //     type: err?.response?.data?.statusCode,
        //     group: err?.response?.data?.exceptionGroup,
        //   },
        // });
      }
    }
  }

  function handleCardPayment() {
    if (!navigator.onLine) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "INTERNET",
        },
      });
      return;
    }

    if (!checkInputs()) return;
    localStorage.setItem("paymentMethod", "card");
    if (fixedAmount) {
      dispatch({ type: SET_LOADING_PAYMENT, payload: true });
    }

    const payload = {
      amount: fixedAmount,
      receiverId: receiverUserId,
      receiverType,
      qrType:
        merchant?.acquiringDetails?.acquiringDetailsType == "PRODUCT" &&
        qrType == "DEFAULT"
          ? "CUSTOM"
          : qrType,
      senderCommissionAmount: commisonAmounts?.standardCommision,
      ...(integratorId && { integratorId }),
      ...((integratorOrderId || productId || selectProducts?.id) && {
        integratorOrderId: integratorOrderId || productId || selectProducts?.id,
      }),
      ...(!!description?.length && { description }),
      ...(!!formData?.payerName?.length && { payerName: formData?.payerName }),
      ...(!!formData?.additionalDescription?.length && {
        purpose: formData?.additionalDescription,
      }),
      ...(!!formData?.payerPersonalNumber?.length && {
        personalNumber: formData?.payerPersonalNumber,
      }),
      currency: curr || "GEL",
      acquiringType: standardPriorityArray[0]?.acquiringType,
    };
    const iv = generateIV();
    const secretKey = generateKey();
    const ivBase64 = convertBase64(iv);
    const secretKeyBase64 = convertBase64(secretKey);
    const base64 = `${secretKeyBase64}.${ivBase64}`;
    const result = encryptAES(payload, secretKey, iv);

    const data = {
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
      encryptedData: result,
      encryptedKeys: encryptJs(base64, { stringify: false }),
    };

    standardTransaction(data)
      .then(({ uri, databaseId }) => {
        localStorage.setItem("databaseId", databaseId);
        window.open(uri, "_self");
      })
      .catch(async ({ response: { data: err } }) => {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.statusCode,
            group: err?.exceptionGroup,
          },
        });
      })
      .finally(() => {
        setTimeout(() => {
          dispatch({ type: SET_LOADING_PAYMENT, payload: false });
        }, 3000);
      });
  }

  const copyToClipboard = () => {
    // Get the current domain

    // Copy the current domain to the clipboard
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setLinkIsCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  async function handleGooglePayPaymenta() {
    if (!navigator.onLine) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "INTERNET",
        },
      });
      return;
    }

    if (!checkInputs()) return;

    if (window.ApplePaySession) {
      setAvailableGoogle(true);
      return;
    }

    localStorage.setItem("paymentMethod", "googlePay");

    if (fixedAmount) {
      dispatch({ type: SET_LOADING_PAYMENT, payload: true });
    }
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);

    const lang = language === "ge" ? "KA" : "EN";
    const payload = {
      convertedAmount:
        curr == "EUR" || curr == "USD"
          ? convertToEur(fixedAmount)
          : Number(fixedAmount),
      amount: Number(fixedAmount) || 20,
      receiverType: receiverType || "User",
      senderCommissionAmount: commisonAmounts?.googleCommision,
      ...(integratorId && { integratorId }),
      ...((integratorOrderId || productId || selectProducts?.id) && {
        integratorOrderId: selectProducts?.id || integratorOrderId || productId,
      }),
      ...(!!description?.length && { description }),
      ...(!!formData?.payerName?.length && { payerName: formData?.payerName }),
      ...(!!formData?.additionalDescription?.length && {
        purpose: formData?.additionalDescription,
      }),
      ...(!!formData?.payerPersonalNumber?.length && {
        personalNumber: formData?.payerPersonalNumber,
      }),
      qrType:
        merchant?.acquiringDetails?.acquiringDetailsType == "PRODUCT" &&
        qrType == "DEFAULT"
          ? "CUSTOM"
          : qrType,
      currency: curr || "GEL",
      fromMobile: false,
      receiverId: receiverUserId,
      lang,
    };
    if (googlePayPriorityArray[0]?.acquiringName == "BOG") {
      const paymentDataRequest = Object.assign({}, baseRequest);
      paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
      paymentDataRequest.transactionInfo = {
        totalPriceStatus: "FINAL",
        totalPrice:
          curr == "EUR" || curr == "USD"
            ? convertToEur(fixedAmount).toString()
            : fixedAmount,
        // totalPrice: fixedAmount,
        currencyCode: "GEL",
        countryCode: "GE",
      };
      paymentDataRequest.merchantInfo = {
        merchantName: MERCHANT_NAME,
        merchantId: GPAY_MERCHANT_ID,
      };

      localStorage.setItem("acquiring", "bog");

      const result = encrypt.encrypt(JSON.stringify(payload));
      let googlePayResponse;
      try {
        googlePayResponse = await startGooglePayPayment({
          encryptedData: result,
          identifier: process.env.REACT_APP_INTEGRATOR_ID,
        });
      } catch ({ response: { data: err } }) {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.statusCode,
            group: err?.exceptionGroup,
          },
        });
        dispatch({ type: SET_LOADING_PAYMENT, payload: false });
        return;
      }

      gpayRef.current
        .loadPaymentData(paymentDataRequest)
        .then(async (paymentData) => {
          try {
            const token = paymentData.paymentMethodData.tokenizationData.token;
            const cardNetwork = paymentData.paymentMethodData.info.cardNetwork;
            const {
              genericId,
              sessionId,
              params: { transaction_id: merchantTransactionId } = {},
            } = googlePayResponse;

            const {
              state,
              url = "",
              result: { status = "" } = {},
              versions,
            } = await acceptGooglePayPayment(token, {
              sessionId,
              merchantTransactionId,
              cardBrand: cardNetwork,
            });

            if (status === "SUCCESS") {
              const redirectURL = `/success?acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
                (merchant || data)?.name
              )}&ratingEnabled=${!!(
                merchant?.reviewRequired || data?.reviewRequired
              )}&tipsEnabled=${!!(
                merchant?.tipsEnabled || data?.tipsEnabled
              )}&receiverId=${receiverUserId}&paymentProviderEnum=BOG&paymentMethod=google&currency=${
                curr || "GEL"
              }&currencyRate=${
                curr == "EUR" || curr == "USD" ? currRate : "0"
              }&genericId=${genericId}&showAwardedGiftComponent=${!!(
                merchant?.showAwardedGiftComponent ||
                data?.showAwardedGiftComponent
              )}`;
              navigate(redirectURL);
              return;
            } else if (state === "3ds2_prepare") {
              const sortedVersions = versions.sort((a, b) => {
                const aParts = a.split(".").map(Number);
                const bParts = b.split(".").map(Number);

                for (let i = 0; i < aParts.length; i++) {
                  if (aParts[i] > bParts[i]) return -1;
                  if (aParts[i] < bParts[i]) return 1;
                }
                return 0;
              });

              localStorage.setItem("version", sortedVersions[0]);

              const options = {
                method: "POST",
                url: `${BOG_URL}/payment/${sessionId}/3ds2-prepare/accept`,
                params: {
                  version: sortedVersions[0],
                  "device.channel": "BRW",
                  ...(sortedVersions[0] === "2.1.0" && {
                    "device.browserAcceptHeader":
                      "application/json, text/javascript, /; q=0.01",
                  }),
                  "device.browserIP": "127.0.0.1",
                  "device.browserLanguage":
                    sortedVersions[0] === "2.1.0" ? "en-US" : "EN",
                  ...(sortedVersions[0] === "2.1.0" && {
                    "device.browserJavaEnabled": false,
                  }),
                  "device.browserColorDepth":
                    sortedVersions[0] === "2.2.0" ? "32" : "24",
                  "device.browserScreenHeight": "800",
                  "device.browserScreenWidth": "480",
                  "device.browserTZ": "180",
                  "device.browserUserAgent":
                    sortedVersions[0] === "2.1.0"
                      ? "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Mobile Safari/537.36"
                      : "Gecko",
                  ...(sortedVersions[0] === "2.2.0" && {
                    "device.browserJavascriptEnabled": false,
                  }),
                  challengeWindowSize:
                    sortedVersions[0] === "2.2.0" ? "02" : "03",
                  // iframeReturnUrl,
                },
              };

              const {
                data: { url, post = {} },
              } = await axios.request(options);
              if (!url) {
                throw new Error("Something went wrong");
              }

              formRef.current.action = url;
              if ("threeDSMethodData" in post) {
                localStorage.setItem("sessionId", sessionId);
                inputRef.current.name = "threeDSMethodData";
                inputRef.current.value = post.threeDSMethodData;
              } else {
                inputRef.current.name = "creq";
                inputRef.current.value = post.creq;
              }

              formRef.current.submit();
            } else if (state === "redirect") {
              window.open(url, "_self");
            } else {
              throw new Error("Unknow state");
            }
          } catch ({ response: { data: err } }) {
            if (integratorCheckOrderId || integratorOrderId) {
              const { redirectRequired, failRedirectUri } =
                await postTransactionAction(integratorOrderId);
              if (redirectRequired && failRedirectUri) {
                window.location.href = failRedirectUri;
                return;
              }
            }
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: err?.statusCode,
                group: err?.exceptionGroup,
              },
            });
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          }
        })
        .catch(() => {
          setTimeout(() => {
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          }, 3000);
        });
    } else if (googlePayPriorityArray[0]?.acquiringName == "TBC") {
      localStorage.setItem("acquiring", "tbc");
      const paymentDataRequest = Object.assign({}, baseRequest);
      paymentDataRequest.allowedPaymentMethods = [cardPaymentMethodTbc];
      paymentDataRequest.transactionInfo = {
        totalPriceStatus: "FINAL",
        totalPrice:
          curr == "EUR" || curr == "USD"
            ? convertToEur(fixedAmount).toString()
            : fixedAmount,
        // totalPrice: fixedAmount,
        currencyCode: "GEL",
        countryCode: "GE",
      };
      paymentDataRequest.merchantInfo = {
        merchantName: "keepz",
        merchantId: "BCR2DN4TWWKZ77LS",
      };

      let googlePayResponse;
      try {
        googlePayResponse = await startTbcGooglePayment(payload);
      } catch ({ response: { data: err } }) {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.statusCode,
            group: err?.exceptionGroup,
          },
        });
        dispatch({ type: SET_LOADING_PAYMENT, payload: false });
        return;
      }

      gpayRef.current
        .loadPaymentData(paymentDataRequest)
        .then(async (paymentData) => {
          try {
            const token = paymentData.paymentMethodData.tokenizationData.token;
            const cardNetwork = paymentData.paymentMethodData.info.cardNetwork;
            const { genericId, transactionId: merchantTransactionId } =
              googlePayResponse;

            const { data } = await acceptTbcGooglePayPayment(
              {
                payment_method: "google_pay",
                google_pay: {
                  card_network: cardNetwork,
                  token: token,
                },
              },
              merchantTransactionId
            );

            if (data?.attributes?.status === "FINISHED") {
              const redirectURL = `/success?acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
                (merchant || data)?.name
              )}&ratingEnabled=${!!(
                merchant?.reviewRequired || data?.reviewRequired
              )}&tipsEnabled=${!!(
                merchant?.tipsEnabled || data?.tipsEnabled
              )}&currency=${
                curr || "GEL"
              }&receiverId=${receiverUserId}&paymentProviderEnum=TBC&paymentMethod=google&currencyRate=${
                curr == "EUR" || curr == "USD" ? currRate : "0"
              }&genericId=${genericId}&showAwardedGiftComponent=${!!(
                merchant?.showAwardedGiftComponent ||
                data?.showAwardedGiftComponent
              )}`;
              navigate(redirectURL);
              return;
            } else if (data?.attributes?.status === "in_progress") {
              console.log("in progress");
            } else if (data?.attributes?.status === "ACTIVE") {
              localStorage.setItem("3dsTbcId", data?.id);
              inputRefUfc.current.value = data?.id;
              formRefUfc.current.submit();
            } else if (data?.attributes?.status === "redirect") {
              window.open(data?.attributes?.status, "_self");
            } else {
              throw new Error("Unknow state");
            }
          } catch ({ response: { data: err } }) {
            console.log("err");
            if (integratorCheckOrderId || integratorOrderId) {
              const { redirectRequired, failRedirectUri } =
                await postTransactionAction(integratorOrderId);
              if (redirectRequired && failRedirectUri) {
                window.location.href = failRedirectUri;
                return;
              }
            }
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: err?.statusCode,
                group: err?.exceptionGroup,
              },
            });
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          }
        })
        .catch((err) => {
          setTimeout(() => {
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          }, 3000);
        });
    } else if (googlePayPriorityArray[0]?.acquiringName == "CREDO") {
      const paymentDataRequest = Object.assign({}, baseRequest);
      paymentDataRequest.allowedPaymentMethods = [
        cardPaymentMethodUfc2(googlePayPriorityArray[0]?.merchantId),
      ];
      paymentDataRequest.transactionInfo = {
        totalPriceStatus: "FINAL",
        totalPrice:
          curr == "EUR" || curr == "USD"
            ? convertToEur(fixedAmount).toString()
            : fixedAmount,
        // totalPrice: fixedAmount,
        currencyCode: "GEL",
        countryCode: "GE",
      };
      paymentDataRequest.merchantInfo = {
        merchantName: "keepz",
        merchantId: googlePayPriorityArray[0]?.googlePayToken,
      };

      let googlePayResponse;
      try {
        googlePayResponse = await startUfcGooglePayment(payload);
      } catch ({ response: { data: err } }) {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.statusCode,
            group: err?.exceptionGroup,
          },
        });
        dispatch({ type: SET_LOADING_PAYMENT, payload: false });
        return;
      }

      gpayRef.current
        .loadPaymentData(paymentDataRequest)
        .then(async (paymentData) => {
          try {
            const token = paymentData.paymentMethodData.tokenizationData.token;
            const cardNetwork = paymentData.paymentMethodData.info.cardNetwork;
            const { genericId, transactionId: merchantTransactionId } =
              googlePayResponse;

            const { data: dataGoogle } = await acceptUfcGooglePayPayment(
              {
                payment_method: "google_pay",
                google_pay: {
                  card_network: cardNetwork,
                  token: token,
                },
              },
              merchantTransactionId
            );

            if (dataGoogle?.attributes?.status === "FINISHED") {
              const redirectURL = `/success?acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
                (merchant || data)?.name
              )}&ratingEnabled=${!!(
                merchant?.reviewRequired || data?.reviewRequired
              )}&tipsEnabled=${!!(
                merchant?.tipsEnabled || data?.tipsEnabled
              )}&currency=${
                curr || "GEL"
              }&receiverId=${receiverUserId}&paymentProviderEnum=CREDO&paymentMethod=google&currencyRate=${
                curr == "EUR" || curr == "USD" ? currRate : "0"
              }&genericId=${genericId}&showAwardedGiftComponent=${!!(
                merchant?.showAwardedGiftComponent ||
                data?.showAwardedGiftComponent
              )}`;
              navigate(redirectURL);
              return;
            } else if (dataGoogle?.attributes?.status === "in_progress") {
              console.log("in progress");
            } else if (dataGoogle?.attributes?.status === "ACTIVE") {
              localStorage.setItem("3dsCredoId", dataGoogle?.id);
              inputRefUfc.current.value = dataGoogle?.id;
              formRefUfc.current.submit();
            } else if (dataGoogle?.attributes?.status === "redirect") {
              window.open(dataGoogle?.attributes?.status, "_self");
            } else {
              throw new Error("Unknow state");
            }
          } catch ({ response: { data: err } }) {
            if (integratorCheckOrderId || integratorOrderId) {
              const { redirectRequired, failRedirectUri } =
                await postTransactionAction(integratorOrderId);
              if (redirectRequired && failRedirectUri) {
                window.location.href = failRedirectUri;
                return;
              }
            }
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: err?.statusCode,
                group: err?.exceptionGroup,
              },
            });
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          }
        })
        .catch((err) => {
          setTimeout(() => {
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          }, 3000);
        });
    }
  }

  useEffect(() => {
    localStorage.removeItem("binance");
    if (receiverUserId == "f5218f97-f9aa-4f9d-b2ec-e041abdf0173") {
      i18n.changeLanguage("ge");
    }
  }, []);

  useEffect(() => {
    const handlePopState = async () => {
      if (!localStorage.getItem("binance")) {
        return;
      }

      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
      const payment_url = localStorage.getItem("binance");
      const payload = {
        externalId: payment_url,
      };
      const encryptedData = encodeURIComponent(
        encrypt.encrypt(JSON.stringify(payload))
      );
      const { encryptedData: res } = await orderCityPayStatus(
        `encryptedData=${encryptedData}&identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      );
      encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const { status, id } = JSON.parse(encrypt.decrypt(res));
      if (status) {
        if (RATING_ALLOWED_STATUSES.includes(status)) {
          const redirectURL = `/success?acquiringTransactionId=${id}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
            (merchant || data)?.name
          )}&ratingEnabled=${!!(
            merchant?.reviewRequired || data?.reviewRequired
          )}`;
          navigate(redirectURL);
        } else {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
            },
          });
        }
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
      localStorage.removeItem("binance");
    };
  }, [merchant, data]);

  let MERCHANT_ID;
  if (applePriorityArray && applePriorityArray[0]?.acquiringName == "BOG") {
    MERCHANT_ID = MERCHANT_IDENTIFIER;
  } else if (
    applePriorityArray &&
    applePriorityArray[0]?.acquiringName == "TBC"
  ) {
    MERCHANT_ID = MERCHANT_IDENTIFIER_TBC;
  } else if (
    applePriorityArray &&
    applePriorityArray[0]?.acquiringName == "CREDO"
  ) {
    MERCHANT_ID = applePriorityArray[0]?.applePayToken;
  }

  // Initialize google pay
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://pay.google.com/gp/p/js/pay.js";
    script.onload = () => {
      // eslint-disable-next-line no-undef
      gpayRef.current = new google.payments.api.PaymentsClient({
        environment: "PRODUCTION", // Change to 'PRODUCTION' for production use
      });
      const isReadyToPayRequest = Object.assign({}, baseRequest);
      isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
      gpayRef.current
        .isReadyToPay(isReadyToPayRequest)
        .then(({ result }) => {
          if (result) {
            dispatch({
              type: SET_GPAY_STATE,
              payload: PAYMENT_SERVICE_STATE.AVAILABLE_PAYMENT_TYPES,
            });
            changePaymentType("gpay");
            dispatch({
              type: SET_PAYMENT_TYPE,
              payload: AVAILABLE_PAYMENT_TYPES.GOOGLE_PAY,
            });
          } else {
            throw new Error("Gpay not available");
          }
        })
        .catch((err) => {
          dispatch({
            type: SET_GPAY_STATE,
            payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
          });
        });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const {
  //         data: { value: data },
  //       } = await paymentServiceInstance.get(
  //         `/api/v1/acquiring/choice/external?receiverId=${encodeURI(
  //           receiverId
  //         )}&receiverType=${encodeURI(receiverType)}`
  //       );

  //       setAcquiringType(data);

  //       return data;
  //     } catch (error) {
  //       console.log("error");
  //     }
  //   };

  //   fetchData();
  // }, []);

  useAcquringChoiceData({
    receiverId,
    receiverType,
    setAcquiringType,
  });

  const { isCalculatingLoading, commisonAmounts } = useConvertedAmountEcomm(
    // acquiringCurrency,
    fixedAmount,
    curr,
    receiverUserId,
    receiverType,
    standardPriorityArray,
    applePriorityArray,
    googlePayPriorityArray,
    false,
    integratorId,
    // setCommisionAmounts,
    setConvertedAmounts
  );

  // Initialize apple pay
  useEffect(() => {
    if (window.ApplePaySession) {
      const promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(MERCHANT_ID);
      promise
        .then((canMakePayments) => {
          if (canMakePayments) {
            changePaymentType("apple");
            dispatch({
              type: SET_PAYMENT_TYPE,
              payload: AVAILABLE_PAYMENT_TYPES.APPLE_PAY,
            });
            dispatch({
              type: SET_APPLE_PAY_STATE,
              payload: PAYMENT_SERVICE_STATE.AVAILABLE,
            });
          } else {
            throw new Error("can't make payments");
          }
        })
        .catch(() => {
          dispatch({
            type: SET_APPLE_PAY_STATE,
            payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
          });
        });
    } else {
      dispatch({
        type: SET_APPLE_PAY_STATE,
        payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
      });
    }
  }, [acquiringType, MERCHANT_ID]);

  useEffect(() => {
    if (
      applePayState !== PAYMENT_SERVICE_STATE.LOADING &&
      gpayState !== PAYMENT_SERVICE_STATE.LOADING &&
      ((status && status.toString() === "FAIL") ||
        transactionStatus === "FAILED")
    ) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "ERROR",
        },
      });
    }
  }, [status, applePayState, transactionStatus, gpayState]);

  useEffect(() => {
    // Set the background color of the body element
    document.body.style.backgroundColor = "#F8F9FF"; // Example color (AliceBlue)

    // Optionally, clean up by resetting the background color when the component is unmounted
    return () => {
      document.body.style.backgroundColor = null;
    };
  }, []);

  useEffect(() => {
    let amountSet =
      requestedAmount ||
      requestedAmount1[0] ||
      data?.amountForDefaultQR ||
      merchant?.amountForDefaultQR;
    if (amountSet && !isNaN(amountSet)) {
      dispatch({ type: SET_AMOUNT, payload: amountSet });
    }
  }, [
    dispatch,
    requestedAmount,
    requestedAmount1[0],
    merchant?.amountForDefaultQR,
    data?.amountForDefaultQR,
  ]);

  useEffect(() => {
    if (qrType == "REQUEST") {
      async function getOrderStatus() {
        try {
          const { prefilledValues, acquiringCurrency } = await checkOrderStatus(
            integratorCheckOrderId,
            integratorId
          );
          // setAcquiringCurrencyReq(acquiringCurrency);
          setReqInputValues(prefilledValues);
          setFormData({
            payerName: prefilledValues?.payerName || "",
            payerPersonalNumber:
              prefilledValues?.personalNumber ||
              prefilledValues?.personalNumberOrPassport ||
              "",
            additionalDescription: prefilledValues?.purpose || "",
          });
          setIsNonResident(prefilledValues?.isForeign || false);
        } catch ({ response: { data: error } }) {
          // setReqQrStatus(error?.statusCode);
        }
      }
      getOrderStatus();
    }
  }, []);

  useEffect(() => {
    async function getProviders() {
      const data = await getOBProviders();
      setObProvider(data);
    }
    getProviders();
  }, []);

  useEffect(() => {
    if (qrCheckError) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: qrCheckError?.response?.data?.statusCode,
          group: qrCheckError?.response?.data?.exceptionGroup,
        },
      });
    }
  }, [qrCheckError]);

  if (integratorCheckOrderId || integratorOrderId) {
    localStorage.setItem(
      "integratorOrderId",
      integratorCheckOrderId || integratorOrderId
    );
  }

  if (
    applePayState === PAYMENT_SERVICE_STATE.LOADING ||
    gpayState === PAYMENT_SERVICE_STATE.LOADING
  ) {
    return <Loader />;
  }

  if (userError?.statusCode === 2189 || merchantError?.statusCode === 2158) {
    return <UserNotFound />;
  }

  if (transactionStatus === "SUCCESS" || transactionStatus === "REFUND") {
    const redirectURL = `/success?receiverId=${receiverUserId}&acquiringTransactionId=${transactionId}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
      (merchant || data)?.name
    )}&ratingEnabled=${!!(receiverType === "BRANCH")}`;
    return <Navigate to={redirectURL} />;
  }

  const decodedString = decodeURIComponent(merchant?.name || data?.name);
  const userName = decodedString.replace(/\+/g, " ");
  const orderNotFound = ["CONFLICT", "NOT_FOUND"].includes(paymentResult?.type);
  return (
    <>
      <div className="w-full h-full bg-[#F8F9FF] ">
        {paymentResult?.show && !orderNotFound ? (
          <div
            className=" flex justify-center    fixed  overflow-hidden top-0 w-[100vw] h-[100vh] bg-black-400 opacity-9 z-40"
            onClick={(e) => {
              if (e.currentTarget !== e.target) {
                return;
              }
              handlePaymentResulClose(paymentResult?.type);
            }}
          >
            <PaymentFeedback
              className={true}
              type={paymentResult?.type}
              action={() => handlePaymentResulClose(paymentResult?.type)}
              group={paymentResult?.group}
            />
          </div>
        ) : null}
        {availableApple ? (
          <EcommerceModal
            Icon={APayIcon}
            desc={"Apple pay not available on your device"}
            subDesc={
              <>
                Please use Mac PC or laptop and Safari as browser <br /> to pay
                with apple pay
              </>
            }
            onClose={() => setAvailableApple(false)}
          />
        ) : null}
        {availableGoogle ? (
          <EcommerceModal
            Icon={GpayIcon}
            desc={"Google pay not available on your device"}
            subDesc={
              <>
                Please use Chrome as browser <br /> to pay with google pay
              </>
            }
            onClose={() => setAvailableGoogle(false)}
          />
        ) : null}
        {loadingPayment && <Loader />}

        <div
          className={`${
            (data || merchant)?.descriptionRequired ? "h-[600px]" : "h-auto"
          }  ${
            hideQR(receiverUserId) ? "w-[400px]" : "w-[774px]"
          }  bg-white m-auto mt-[67px] p-[40px] rounded-[16px] z-10 relative`}
        >
          {selectProducts?.show ? (
            <ProductList
              products={(merchant || data)?.acquiringDetails?.productList}
              dispatch={dispatch}
              productId={selectProducts?.id}
            />
          ) : (
            <>
              {(isOBOpen || isCryptoOpen) && (
                <svg
                  width="12"
                  height="21"
                  viewBox="0 0 12 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ms-[-35px] mt-[-20px] cursor-pointer"
                  onClick={() =>
                    isOBOpen ? setIsOBOpen(false) : setIsCryptoOpen(false)
                  }
                >
                  <path
                    d="M0 10.5021C0.000155599 10.726 0.0481078 10.9473 0.140652 11.1513C0.233196 11.3552 0.368199 11.537 0.53664 11.6846L9.4328 20.3821C9.5671 20.5245 9.7298 20.6372 9.91038 20.7128C10.091 20.7884 10.2854 20.8252 10.4811 20.821C10.6799 20.8221 10.8769 20.7837 11.0607 20.7082C11.2445 20.6326 11.4116 20.5214 11.5521 20.3808C11.6926 20.2403 11.8039 20.0733 11.8795 19.8894C11.955 19.7056 11.9933 19.5086 11.9922 19.3098C11.9911 19.1073 11.9496 18.9071 11.8701 18.7209C11.7907 18.5346 11.6748 18.3661 11.5294 18.2251L3.59528 10.5021L11.5294 2.77489C11.8211 2.48877 11.9875 2.09872 11.9922 1.69017C11.9933 1.49141 11.955 1.29442 11.8795 1.11058C11.8039 0.926745 11.6926 0.759722 11.5521 0.619181C11.4116 0.478641 11.2445 0.367375 11.0607 0.291825C10.8769 0.216275 10.6799 0.177941 10.4811 0.179045C10.2854 0.174768 10.091 0.211622 9.91038 0.287223C9.7298 0.362823 9.5671 0.475486 9.4328 0.617926L0.53664 9.304C0.369651 9.4561 0.235811 9.64099 0.143478 9.84713C0.0511457 10.0533 0.0023029 10.2762 0 10.5021Z"
                    fill="black"
                  />
                </svg>
              )}
              <div className="w-full flex justify-between items-center">
                <div className="flex gap-[10px] items-center">
                  <div className="w-[70px] h-[70px] bg-gray-100 rounded-[50%]">
                    <img
                      src={getImage()}
                      className="aspect-square rounded-full border-white object-cover"
                      width={"100"}
                      height={"100"}
                      alt="user icon"
                    />
                  </div>

                  <p className="text-[#170738] text-[20px] font-[700] break-words">
                    <p className="truncate  px-1">{userName}</p>
                  </p>
                </div>
                <div className="flex gap-[10px] items-center">
                  {requestedAmount1?.length > 1 && (
                    <button
                      onClick={() => {
                        setCurrentIndex((prevIndex) => {
                          const newIndex = prevIndex - 1; // Calculate the new index
                          const isWithinBounds = newIndex >= 0;

                          if (isWithinBounds) {
                            dispatch({
                              type: "SET_AMOUNT",
                              payload: requestedAmount1[newIndex],
                            });
                          }
                          return isWithinBounds ? newIndex : prevIndex;
                        });
                      }}
                      className="cursor-pointer"
                    >
                      <MinusIcon
                        color={currentIndex == 0 ? "#DADCDD" : "#6C63FF"}
                      />
                    </button>
                  )}
                  <div>
                    <p className="text-[#000000] text-[14px] opacity-[50%] text-right">
                      {(merchant || data)?.acquiringDetails
                        ?.acquiringDetailsType == "PRODUCT" &&
                      qrType == "DEFAULT"
                        ? t("amountToPay")
                        : t("amount")}
                    </p>
                    <p className="text-[#000000] font-[700] text-[24px] ">
                      {fixedAmount || "0.00"}
                      <span>
                        {curr == "EUR" && "€"}
                        {curr == "GEL" && "₾"}
                        {curr == "USD" && "$"}
                      </span>
                    </p>
                  </div>
                  {requestedAmount1?.length > 1 && (
                    <button
                      className="cursor-pointer"
                      onClick={() => {
                        setCurrentIndex((prevIndex) => {
                          const newIndex = prevIndex + 1;
                          const isWithinBounds =
                            newIndex < requestedAmount1?.length;

                          if (isWithinBounds) {
                            dispatch({
                              type: "SET_AMOUNT",
                              payload: requestedAmount1[newIndex],
                            });
                          }

                          return isWithinBounds ? newIndex : prevIndex;
                        });
                      }}
                    >
                      <PlusIcon
                        color={
                          currentIndex == requestedAmount1?.length - 1
                            ? "#DADCDD"
                            : "#6C63FF"
                        }
                      />
                    </button>
                  )}
                </div>
              </div>

              {(data || merchant)?.descriptionRequired && (
                <>
                  <div className="mt-[10px]">
                    <CustomInput
                      name="description"
                      placeholder={t("inputs.description")}
                      ref={descriptionRef}
                      onChange={(e) => {
                        setDescription(e.value);
                      }}
                      value={description}
                      backgroundColor="#FAFAFA"
                      width="100%"
                      margin="0 0 0 0"
                      borderRadius="16px"
                      descError={descError}
                    />
                  </div>
                </>
              )}

              {merchant?.payerNameRequired && (
                <div className="mt-[10px]">
                  <CustomInput
                    name="payerName"
                    placeholder={t("inputs.payerName")}
                    ref={payerNameRef}
                    onChange={(e) => {
                      const { name, value } = e;
                      setFormData((prevState) => ({
                        ...prevState,
                        [name]: value.replace(
                          /^[^a-zA-Z\p{L}]+|[^\p{L}\s]/gu,
                          ""
                        ),
                      }));
                    }}
                    value={formData?.payerName}
                    disabled={
                      prefilledValues?.payerName || reqInputValues?.payerName
                        ? true
                        : false
                    }
                    backgroundColor="#FAFAFA"
                    width="100%"
                    margin="0 0 0 0"
                    borderRadius="16px"
                  />
                </div>
              )}
              {(merchant?.personalNumberRequired ||
                merchant?.personalNumberOrPassportRequired) && (
                <div className="mt-[10px]">
                  <CustomInput
                    name="payerPersonalNumber"
                    placeholder={
                      merchant?.personalNumberRequired
                        ? t("inputs.payerPersonalNumber")
                        : t("inputs.personalOrPassport")
                    }
                    ref={payerPersonalNumberRef}
                    onChange={(e) => {
                      const { name, value } = e;
                      setFormData((prevState) => ({
                        ...prevState,
                        [name]:
                          merchant?.personalNumberRequired && !isNonResident
                            ? value.replace(/[^0-9]/g, "")
                            : value,
                      }));
                    }}
                    value={formData?.payerPersonalNumber}
                    disabled={
                      prefilledValues?.personalNumber ||
                      prefilledValues?.personalNumberOrPassport ||
                      reqInputValues?.personalNumber ||
                      reqInputValues?.personalNumberOrPassport
                        ? true
                        : false
                    }
                    backgroundColor="#FAFAFA"
                    width="100%"
                    margin="0 0 0 0"
                    borderRadius="16px"
                    // type={"number"}
                    error={!isNonResident && personalNumberError}
                  />
                </div>
              )}
              {merchant?.personalNumberRequired && (
                <div className="flex justify-start items-center gap-[7px] mt-3">
                  <input
                    id="checkBox"
                    type="checkbox"
                    disabled={
                      prefilledValues?.personalNumber ||
                      prefilledValues?.personalNumberOrPassport ||
                      reqInputValues?.personalNumber ||
                      reqInputValues?.personalNumberOrPassport
                    }
                    checked={isNonResident}
                    onChange={(event) => {
                      setIsNonResident(event.target.checked);
                      setFormData((prevState) => ({
                        ...prevState,
                        payerPersonalNumber: "",
                      }));
                    }}
                    className=" w-[18px] h-[18px]  bg-white accent-[#6C63FF] cursor-pointer "
                  />
                  <label
                    htmlFor="checkBox"
                    className="text-[#454545] text-[14px]"
                  >
                    {t("inputs.noneResident")}
                  </label>
                </div>
              )}

              {(merchant || data)?.acquiringDetails?.acquiringDetailsType ==
                "PRODUCT" &&
                qrType == "DEFAULT" && (
                  <div className="mt-[10px]">
                    <ProductSelect
                      name={selectProducts?.name}
                      onClick={() =>
                        dispatch({
                          type: SET_PRODUCTS,
                          payload: {
                            show: true,
                            id: selectProducts?.id || null,
                          },
                        })
                      }
                    />
                  </div>
                )}

              {merchant?.purposeRequired && (
                <div className="mt-[10px]">
                  <CustomInput
                    name="additionalDescription"
                    placeholder={t("inputs.additionaDescription")}
                    disabled={
                      prefilledValues?.purpose || reqInputValues?.purpose
                        ? true
                        : false
                    }
                    ref={additionalDescriptionRef}
                    onChange={(e) => {
                      const { name, value } = e;
                      setFormData((prevState) => ({
                        ...prevState,
                        [name]: value,
                      }));
                    }}
                    value={formData?.additionalDescription}
                    backgroundColor="#FAFAFA"
                    width="100%"
                    margin="0 0 0 0"
                    borderRadius="16px"
                  />
                </div>
              )}

              <div
                className={`flex items-stretch justify-between gap-[20px] mt-[31px] `}
              >
                {isOBOpen && (
                  <>
                    <div className="flex flex-col gap-[25px] w-[337px]">
                      {ObProvider.map((el, index) => (
                        <OBPaymentMethod
                          key={index}
                          name={el.name}
                          // Photo={el.icon}
                          // paymentType={el.paymentType}
                          // dispatch={dispatch}
                          fn={() => handleOBPayment(el.id, index)}
                          shouldHaveArrow={true}
                          isLoading={index === isOBLoadingIndex}
                          isLoadingOB={isLoadingOB}
                        />
                      ))}
                    </div>
                  </>
                )}
                {isCryptoOpen && (
                  <>
                    <div className="flex flex-col gap-[25px] w-[337px]">
                      {acquiringType?.availableAcquiringTypes?.CRYPTO?.sort(
                        (a, b) => a.priority - b.priority
                      ).map((el, index) => (
                        <CryptoPaymentMethod
                          key={index}
                          name={el.acquiringName}
                          logo={el.logo}
                          // acquiringType={el.acquiringType}
                          fn={() =>
                            handleCryptoPayment(el.acquiringType, index)
                          }
                          shouldHaveArrow={true}
                          isLoading={index === isOBLoadingIndex}
                          isLoadingOB={isLoadingOB}
                          // setCryptoAcquringType={setCryptoAcquringType}
                        />
                      ))}
                    </div>
                  </>
                )}
                {!isCryptoOpen && !isOBOpen && (
                  <div className="w-[337px] flex-1 flex flex-col gap-[15px]">
                    {acquiringType?.availableAcquiringTypes?.APPLE_PAY && (
                      <button
                        onClick={handleApplePayment}
                        className="flex items-center justify-between w-full cursor-pointer bg-[#6C63FF]/[5%]  pt-[10px] pr-[20px] pb-[10px] ps-[15px] border-[1px] border-[#DADCDD80] hover:border-[#6C63FF]/[50%]  rounded-[12px] h-[60px] "
                      >
                        <div className="flex items-center gap-[10px]">
                          <ApplePayIcon className="w-[38px] h-[25px]" />
                          <div className="text-left">
                            <p className="text-[16px] text-[#170738]">
                              Apple Pay
                            </p>
                            {commisonAmounts?.appleCommision ? (
                              <p className="text-[12px]  text-[#170738] opacity-30">
                                Total with fees:
                                {Number(
                                  formatter(
                                    commisonAmounts?.appleCommision
                                  ).add(fixedAmount)
                                )}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <ArrowRightIcon />
                      </button>
                    )}
                    {acquiringType?.availableAcquiringTypes?.GOOGLE_PAY && (
                      <button
                        onClick={handleGooglePayPaymenta}
                        className="flex items-center justify-between w-full cursor-pointer bg-[#6C63FF]/[5%]  pt-[10px] pr-[20px] pb-[10px] ps-[15px] border-[1px] border-[#DADCDD80] hover:border-[#6C63FF]/[50%]  rounded-[12px] h-[60px]  "
                      >
                        <div className="flex items-center gap-[10px]">
                          <GooglePay className="w-[38px] h-[25px]" />
                          <div className="text-left">
                            <p className="text-[16px] text-[#170738]">
                              Google Pay
                            </p>
                            {commisonAmounts?.googleCommision ? (
                              <p className="text-[12px]  text-[#170738] opacity-30">
                                Total with fees:
                                {Number(
                                  formatter(
                                    commisonAmounts?.googleCommision
                                  ).add(fixedAmount)
                                )}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <ArrowRightIcon />
                      </button>
                    )}
                    {acquiringType?.availableAcquiringTypes?.STANDARD && (
                      <button
                        className="flex items-center justify-between w-full cursor-pointer bg-[#6C63FF]/[5%]  pt-[10px] pr-[20px] pb-[10px] ps-[15px] border-[1px] border-[#DADCDD80] hover:border-[#6C63FF]/[50%]  rounded-[12px] h-[60px] "
                        onClick={handleCardPayment}
                      >
                        <div className="flex items-center gap-[10px]">
                          <CardIcon className="w-[30px] h-[17px]" />
                          <div className="text-left">
                            <p className="text-[16px] text-[#170738]">
                              {t("description.payzePayWithCard")}
                            </p>
                            {commisonAmounts?.standardCommision ? (
                              <p className="text-[12px] text-[#170738] opacity-30">
                                Total with fees:
                                {Number(
                                  formatter(
                                    commisonAmounts?.standardCommision
                                  ).add(fixedAmount)
                                )}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="flex gap-[20px]">
                          <VisaMasterIcon />
                          <ArrowRightIcon />
                        </div>
                      </button>
                    )}
                    {acquiringType?.availableAcquiringTypes?.OPEN_BANKING && (
                      <button
                        onClick={() => setIsOBOpen(true)}
                        className="flex items-center justify-between w-full cursor-pointer bg-[#6C63FF]/[5%]  pt-[10px] pr-[20px] pb-[10px] ps-[15px] border-[1px] border-[#DADCDD80] hover:border-[#6C63FF]/[50%] rounded-[12px] h-[60px] "
                      >
                        <div className="flex items-center  gap-[10px]">
                          <OnlineBankIcon className="w-[30px] h-[23px]" />
                          <div className="text-left">
                            <p className="text-[16px] text-[#170738]">
                              {t("onlineBank")}
                            </p>
                            {commisonAmounts?.openBankingCommision ? (
                              <p className="text-[12px]  text-[#170738] opacity-30">
                                Total with fees:
                                {Number(
                                  formatter(
                                    commisonAmounts?.openBankingCommision
                                  ).add(fixedAmount)
                                )}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex items-center gap-[20px]">
                          <TbcBogIcon />
                          <ArrowRightIcon />
                        </div>
                      </button>
                    )}
                    {acquiringType?.availableAcquiringTypes?.CRYPTO && (
                      <button
                        onClick={() => setIsCryptoOpen(true)}
                        className="flex items-center justify-between w-full cursor-pointer bg-[#6C63FF]/[5%]  pt-[10px] pr-[20px] pb-[10px] ps-[15px] border-[1px] border-[#DADCDD80] hover:border-[#6C63FF]/[50%]  rounded-[12px] h-[60px] "
                      >
                        <div className="flex items-center  gap-[10px]">
                          <BitCoinIcon />
                          <div className="text-left ">
                            <p className="text-[16px] text-[#170738]">Crypto</p>
                            {commisonAmounts?.cityPayCommision ? (
                              <p className="text-[12px] text-[#170738] opacity-30">
                                Total with fees:
                                {Number(
                                  formatter(
                                    commisonAmounts?.cityPayCommision
                                  ).add(fixedAmount)
                                )}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="flex items-center gap-[20px]">
                          <CryptosIcon />
                          <ArrowRightIcon />
                        </div>
                      </button>
                    )}
                    {acquiringType?.availableAcquiringTypes?.INSTALLMENT && (
                      <div
                        onClick={() => {
                          dispatch({ type: SET_TOGGLE_INSTALLMENT_MODAL });
                          changePaymentType("installment");
                        }}
                        className="flex items-center justify-between w-full cursor-pointer bg-[#6C63FF]/[5%]  pt-[10px] pr-[20px] pb-[10px] ps-[15px] border-[1px] border-[#DADCDD80] hover:border-[#6C63FF]/[50%] rounded-[12px] h-[60px] "
                      >
                        <div className="flex items-center gap-[7px]">
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M23.0989 12.6113C23.0989 7.49481 18.8977 2.41584 12.3575 2.41584C7.07542 2.41584 4.46481 6.12667 3.35238 8.10008H6.65399C7.17897 8.10008 7.60456 8.50404 7.60456 9.00233C7.60456 9.50063 7.17897 9.90459 6.65399 9.90459H0.95057C0.425589 9.90459 0 9.50063 0 9.00233V3.58881C0 3.09051 0.425589 2.68656 0.95057 2.68656C1.47555 2.68656 1.90114 3.09051 1.90114 3.58881V6.86071C3.24055 4.61965 6.33852 0.611328 12.3575 0.611328C20.0814 0.611328 25 6.63007 25 12.6113C25 18.5926 20.0814 24.6113 12.3575 24.6113C8.66194 24.6113 5.58513 23.2233 3.39445 21.1077C2.22152 19.9748 1.30548 18.6366 0.680913 17.1961C0.481103 16.7353 0.7127 16.208 1.19817 16.0184C1.68365 15.8287 2.23918 16.0485 2.43897 16.5093C2.97289 17.7407 3.75466 18.8811 4.75038 19.8427C6.59795 21.6272 9.19194 22.8068 12.3575 22.8068C18.8977 22.8068 23.0989 17.7279 23.0989 12.6113ZM12.3574 6.29559C12.8824 6.29559 13.308 6.69955 13.308 7.19784V12.2377L16.8318 15.5824C17.203 15.9347 17.203 16.506 16.8318 16.8584C16.4606 17.2107 15.8588 17.2107 15.4875 16.8584L11.6853 13.2494C11.507 13.0802 11.4068 12.8507 11.4068 12.6114V7.19784C11.4068 6.69955 11.8324 6.29559 12.3574 6.29559Z"
                              fill="#170738"
                            />
                          </svg>
                          <p className="text-[16px] ">{t("installmentText")}</p>
                        </div>
                        <p className="bg-[#6C63FF] text-[12px] text-white rounded-[5px] w-[35px] h-[20px] flex justify-center items-center">
                          New
                        </p>
                      </div>
                    )}
                  </div>
                )}

                {!hideQR(receiverUserId) && (
                  <div className="w-[337px] min-h-[360px] flex-1 border-[2px] rounded-[12px] flex flex-col justify-between  items-center py-[40px]">
                    <p className="opacity-50">Scan and pay with smartphone</p>
                    <div className="w-[215px] h-[215px]">
                      <QRCode
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "fill",
                        }}
                        value={window.location.href}
                      />
                    </div>

                    {!linkIsCopied ? (
                      <div className="flex items-center gap-[5px]">
                        <svg
                          width="17"
                          height="10"
                          viewBox="0 0 17 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.63793 2.3C3.97946 2.3 3.34796 2.58446 2.88236 3.09081C2.41675 3.59716 2.15517 4.28392 2.15517 5C2.15517 5.71608 2.41675 6.40284 2.88236 6.90919C3.34796 7.41554 3.97946 7.7 4.63793 7.7H6.59172C7.04879 7.7 7.41931 8.10294 7.41931 8.6C7.41931 9.09706 7.04879 9.5 6.59172 9.5H4.63793C3.54048 9.5 2.48798 9.0259 1.71197 8.18198C0.935959 7.33807 0.5 6.19347 0.5 5C0.5 3.80653 0.93596 2.66193 1.71197 1.81802C2.48798 0.974106 3.54048 0.5 4.63793 0.5H6.54862C7.00568 0.5 7.37621 0.902944 7.37621 1.4C7.37621 1.89706 7.00568 2.3 6.54862 2.3H4.63793ZM9.62379 1.4C9.62379 0.902944 9.99432 0.5 10.4514 0.5H12.3621C13.4595 0.5 14.512 0.974106 15.288 1.81802C16.064 2.66193 16.5 3.80653 16.5 5C16.5 6.19347 16.064 7.33807 15.288 8.18198C14.512 9.0259 13.4595 9.5 12.3621 9.5H10.4083C9.95121 9.5 9.58069 9.09706 9.58069 8.6C9.58069 8.10294 9.95121 7.7 10.4083 7.7H12.3621C13.0205 7.7 13.652 7.41554 14.1176 6.90919C14.5833 6.40284 14.8448 5.71608 14.8448 5C14.8448 4.28392 14.5833 3.59716 14.1176 3.09081C13.652 2.58446 13.0205 2.3 12.3621 2.3H10.4514C9.99432 2.3 9.62379 1.89706 9.62379 1.4ZM4.67483 5C4.67483 4.50294 5.04535 4.1 5.50241 4.1H11.5666C12.0236 4.1 12.3941 4.50294 12.3941 5C12.3941 5.49706 12.0236 5.9 11.5666 5.9H5.50241C5.04535 5.9 4.67483 5.49706 4.67483 5Z"
                            fill="#6C63FF"
                          />
                        </svg>

                        <button
                          onClick={copyToClipboard}
                          className="text-[#6C63FF] text-[16px] font-sans"
                        >
                          Copy payment link
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center gap-[5px]">
                        <svg
                          width="15"
                          height="10"
                          viewBox="0 0 15 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.5 4.33333C2.00526 4.86667 4.65789 7.66667 5.92105 9L13.5 1"
                            stroke="#8CC63F"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <p className="text-green text-[16px] font-sans">
                          Link is copied
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className=" w-full gap-[11px] flex items-center justify-center -translate-x-[99px]  mt-[-55px] z-0 absolute ">
          <svg
            width="207"
            height="229"
            viewBox="0 0 207 229"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.1"
              d="M33.0359 228.969C21.6248 228.969 10.528 223.054 4.42954 212.452C-4.68676 196.658 0.720151 176.461 16.5008 167.337L107.978 114.483L16.5008 61.6288C0.720151 52.5051 -4.68676 32.3073 4.42954 16.514C13.5458 0.720729 33.7274 -4.69052 49.5081 4.4331L190.496 85.885C200.713 91.7996 207 102.685 207 114.483C207 126.281 200.713 137.229 190.496 143.112L49.5081 224.564C44.3212 227.553 38.6314 229 33.0359 229V228.969Z"
              fill="#6C63FF"
            />
          </svg>
          <p className="text-[#170738] text-[14px]">Powered by</p>

          <Hlogo width={"130px"} height={"30px"} />
        </div>

        <form ref={formRef} method="post" action="">
          <input type="hidden" name="creq" ref={inputRef} value="" />
          <noscript>
            <center>
              <br />
              Please click the submit button below.
              <br />
              <input type="submit" value="Submit" />
            </center>
          </noscript>
        </form>
        <form
          ref={formRefUfc}
          name="returnform"
          method="post"
          action="https://ecommerce.ufc.ge/ecomm2/ClientHandler"
        >
          <input type="hidden" name="trans_id" ref={inputRefUfc} value="" />
          <noscript>
            <center>
              <br />
              Please click the submit button below.
              <br />
              <input type="submit" name="submit" value="Submit" />
            </center>
          </noscript>
        </form>
        <div className="w-full h-[50px] flex justify-center gap-[20px] items-center bg-white  fixed bottom-0 z-[50]">
          <p className="text-center text-[14px]">&copy; Keepz.me - 2024</p>
          <Link
            to="/terms-and-conditions"
            className="text-[14px] cursor-pointer text-[#170738]"
          >
            <Trans i18nKey={"termsAndCondition"} />
          </Link>
          <Link
            className="text-[14px] cursor-pointer text-[#170738]"
            to="/privacy"
          >
            <Trans i18nKey={"privacyPolicy"} />
          </Link>
          <a
            className="text-[14px] text-[#170738] "
            href={`${window.location.href}SupplementaryContractualProvisions.pdf`}
          >
            Contractual Provisions
          </a>
          <div
            className="flex items-center gap-[3px] cursor-pointer"
            onClick={handleLangChange}
          >
            <img src={lngIcon} />
            <p>{language == "en" ? "GEO" : "ENG"}</p>
          </div>
        </div>
      </div>
      <Modal
        width="70%"
        show={showInstallmentModal}
        position="center"
        onClose={() => dispatch({ type: SET_TOGGLE_INSTALLMENT_MODAL })}
      >
        <InstallmentModal
          dispatch={dispatch}
          installmentProperties={installmentProperties}
          language={language}
          payload={{
            amount: fixedAmount,
            receiverId: receiverUserId,
            receiverType,
            qrType:
              merchant?.acquiringDetails?.acquiringDetailsType == "PRODUCT" &&
              qrType == "DEFAULT"
                ? "CUSTOM"
                : qrType,
            // senderCommissionAmount: commisonAmounts?.installmentCommision,
            ...(integratorId && { integratorId }),
            ...((integratorOrderId || productId || selectProducts?.id) && {
              integratorOrderId:
                selectProducts?.id || integratorOrderId || productId,
            }),
            ...(!!description?.length && { description }),
            ...(!!formData?.payerName?.length && {
              payerName: formData?.payerName,
            }),
            ...(!!formData?.additionalDescription?.length && {
              purpose: formData?.additionalDescription,
            }),
            ...(!!formData?.payerPersonalNumber?.length && {
              personalNumber: formData?.payerPersonalNumber,
            }),
            currency: curr || "GEL",
            acquiringType: "CREDO_INSTALLMENT",
          }}
        />
      </Modal>
    </>
  );
};

export default BogPaymentWeb;
