import CustomInput from "components/CustomInput";
import img from "../../assets/images/mtiCampaign.png";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { t } from "i18next";
import { getPromoCode, sendNumber } from "api";
import { Trans } from "react-i18next";
import SkiPassLottie from "../../utils/skiPassLottie.json";
import Lottie from "lottie-react";
import SkiPassTick from "assets/icons/SkiPassTick";
import PaymentFeedback from "components/PaymentFeedback";
import skiPassIcon from "../../assets/images/icon/SkiPassApp.png";
import tktIcon from "../../assets/images/icon/tkt.png";
import biletebiIcon from "../../assets/images/icon/biletebi.png";
import mtiIcon from "../../assets/images/icon/mti.png";

const SkiCampaignPage = ({
  data,
  setShowCampaign,
  paymentProviderEnum,
  transactionId,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isNumberSent, setIsNumberSent] = useState(false);
  const [voucherCode, setVoucherCode] = useState();
  const [showLottie, setShowLottie] = useState(true);
  const [checkedInputs, setCheckedInputs] = useState({});
  const [error, setError] = useState({ show: false, type: null, group: null });
  const audioRef = useRef();

  const inputRef = useRef(null);

  const sendNumberHanlder = async () => {
    if (inputValue.length != 9) {
      inputRef.current.focus();
      return;
    }
    const payload = {
      paymentProvider: paymentProviderEnum,
      phone: inputValue,
      // transactionId: transactionId,
      transactionId: transactionId,
    };
    try {
      const data = await sendNumber(payload);
      setIsNumberSent(true);
    } catch (error) {
      console.log(error);
    }
  };

  let content = isNumberSent ? (
    <div className="flex justify-center items-center gap-[5px] rounded-[16px] mt-[20px] bg-[#8CC63F1A] h-[70px] w-[90%]">
      <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 6.03288C1.67368 6.69954 5.21053 10.1995 6.89474 11.8662L17 1.86621"
          stroke="#8CC63F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p className="text-[14px] px-[10px]">
        <Trans i18nKey={"campaign.smsSent"} />
      </p>
    </div>
  ) : (
    <>
      <div className="mt-[20px] w-[90%]">
        <CustomInput
          name="additionalDescription"
          placeholder={t("inputs.enterNumber")}
          ref={inputRef}
          onChange={(e) => {
            const regex = /^\d{0,9}$/; // Allow only 9 digits
            if (regex.test(e.value)) {
              setInputValue(e.value);
            }
          }}
          value={inputValue}
          width={"100%"}
          margin="0 0 0 0"
          borderRadius="16px"
          type="text"
        />
      </div>
      <button
        onClick={sendNumberHanlder}
        className="rounded-[13px] w-[90%] p-[10px] bg-[#6C63FF] text-white mt-[20px]"
      >
        Send code via SMS
      </button>
    </>
  );
  const voice = useMemo(() => {
    return new Audio(
      "https://wallet-keepz-s3.s3.eu-central-1.amazonaws.com/web/ski-pass-audio.mp3"
    );
  }, []);
  useLayoutEffect(() => {
    if (voucherCode) {
      voice.play();
    }
  }, [voucherCode]);

  function renderIcons(name) {
    if (name == "Skipass App") {
      return <img width={"40px"} height={"40px"} src={skiPassIcon} />;
    } else if (name == "TKT.ge") {
      return <img width={"40px"} height={"40px"} src={tktIcon} />;
    } else if (name == "Biletebi.ge") {
      return <img width={"40px"} height={"40px"} src={biletebiIcon} />;
    } else if (name == "M.T.A. cashdesk") {
      return <img width={"75px"} height={"40px"} src={mtiIcon} />;
    }
  }

  return (
    <div className="max-w-[480px] h-full m-auto flex flex-col items-center justify-around  font-display  ">
      {showLottie && voucherCode && (
        <Lottie
          animationData={SkiPassLottie}
          loop={false}
          style={{ position: "absolute" }}
          onComplete={() => setShowLottie(false)}
        />
      )}

      <div className=" w-[100%] flex flex-col items-center font-display  ">
        <img src={img} onClick={() => console.log("go")} />
        <h2
          onClick={() => console.log("go")}
          className="text-center text-[24px] font-bold text-[#6C63FF] leading-[27px] mt-[20px]"
        >
          <Trans i18nKey={"campaign.congrats"} />
        </h2>

        {voucherCode ? (
          <>
            <div className="px-[28px] py-[14px] bg-[#6C63FF1A] rounded-[22px] text-[33px] text-center font-bold text-[#6C63FF] mt-[30px]">
              {voucherCode}
            </div>
            {Object.entries(checkedInputs)[0][0] == "M.T.A. cashdesk" ? (
              <p className="text-center mt-[30px]">
                <Trans i18nKey={"campaign.showCode"} />
              </p>
            ) : (
              <p className="text-center mt-[30px]">
                <Trans
                  i18nKey={"campaign.showCodeTwo"}
                  values={{ vendor: Object.entries(checkedInputs)[0][0] }}
                />
              </p>
            )}

            {content}
          </>
        ) : (
          <div className="w-[90%] mt-[40px] ">
            <div className="w-[100%] h-[290px] overflow-scroll">
              {data?.voucher?.vendorList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="bg-[#FAFAFA] cursor-pointer w-[100%] mt-[20px] py-[10px] px-[20px] rounded-[16px] flex justify-between items-center"
                    onClick={() => {
                      setCheckedInputs((prevState) => {
                        return {
                          [item]: item,
                        };
                      });
                    }}
                  >
                    <div className="flex items-center gap-[10px] ">
                      {renderIcons(item)}
                      <p>{item}</p>
                    </div>
                    {checkedInputs[item] && <SkiPassTick />}
                  </div>
                );
              })}
            </div>
            <button
              onClick={async () => {
                try {
                  const data = await getPromoCode(
                    Object.entries(checkedInputs)[0][0],
                    transactionId,
                    paymentProviderEnum
                  );
                  setVoucherCode(data?.voucherCode);
                } catch ({ response: { data: err } }) {
                  setError({
                    show: true,
                    type: err?.statusCode,
                    group: err?.exceptionGroup,
                  });
                }
              }}
              className="w-[100%] cursor-pointer mt-[20px] bg-[#6C63FF] p-[10px] rounded-[13px] text-white disabled:opacity-50"
              disabled={!Object.keys(checkedInputs).length > 0}
            >
              Get the promo code
            </button>
          </div>
        )}
      </div>
      {voucherCode && (
        <button
          onClick={() => setShowCampaign()}
          className="text-[18px] text-[#6C63FF]"
        >
          <Trans i18nKey={"campaign.buttonClose"} />
        </button>
      )}
      {error?.show && (
        <div
          className=" flex justify-center    fixed  overflow-hidden top-0 w-[100vw] h-[100vh] bg-black-400 opacity-9 z-40"
          onClick={(e) => {
            if (e.currentTarget !== e.target) {
              return;
            }
          }}
        >
          <PaymentFeedback
            className={true}
            type={error?.type}
            action={() => {
              setError((prev) => {
                return { ...prev, show: !prev.show };
              });
            }}
            group={error?.group}
          />
        </div>
      )}
    </div>
  );
};

export default SkiCampaignPage;
