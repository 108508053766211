import useSWR from "swr";
import { eCommerceServiceInstance, paymentServiceInstance } from "api";
import {
  convertBase64,
  decrypt,
  decryptAES,
  encrypt,
  encryptAES,
  generateIV,
  generateKey,
  parseBase64,
} from "utils/encryption";

export const usePaidDetailsData = (
  statusCode,
  integratorId,
  integratorOrderId
) => {
  console.log("shemovida hookshi");
  const { data: transactionDetails } = useSWR(
    statusCode == 6016
      ? `/api/integrator/order/invoice/external?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    async (url) => {
      console.log(url);
      try {
        const payload = {
          integratorId: integratorId,
          integratorOrderId: integratorOrderId,
        };
        const IV = generateIV();
        const SECRETKEY = generateKey();
        const ivBase64 = convertBase64(IV);
        const secretKeyBase64 = convertBase64(SECRETKEY);
        const base64 = `${secretKeyBase64}.${ivBase64}`;
        const encryptedBase64 = encrypt(base64, {
          stringify: false,
          shouldEncode: true,
        });
        const encrypteData = encryptAES(payload, SECRETKEY, IV, {
          shouldEncode: true,
        });
        const {
          data: {
            value: { encryptedData, encryptedKeys },
          },
        } = await eCommerceServiceInstance.get(
          url +
            `&encryptedData=${encrypteData}&encryptedKeys=${encryptedBase64}`
        );

        // Decrypt the secret key
        const encrypteSecretKey = decrypt(encryptedKeys, {
          shouldParse: false,
        });

        // Split into key and IV
        const [base64SecretKey, base64IV] = encrypteSecretKey.split(".");
        const secretKey = parseBase64(base64SecretKey);
        const iv = parseBase64(base64IV);

        // Decrypt the actual data
        const decryptedData = decryptAES(encryptedData, secretKey, iv);
        console.log(decryptedData, "transaction invoice data");
        return decryptedData;
      } catch (error) {
        console.log(error, "what is happening here");
      }
    }
  );

  return {
    transactionDetails,
  };
};
