import { convertCurrency, getCommisionAmount } from "api";
import { AVAILABLE_PAYMENT_TYPES } from "features/bog/config";
import { SET_CONVERTED_AMOUNTS } from "features/bog/reducer/actions";
import { useState, useEffect } from "react";

function useConvertedAmount(
  // acquiringCurrency,
  amount = null,
  currency = "",
  paymentType = null,
  receiverId = "",
  receiverType = "",
  standardPriorityArray = [],
  applePriorityArray = [],
  googlePayPriorityArray = [],
  isTip = false,
  integratorId = "",
  setCommisionAmount = null,
  setConvertedAmounts,
  paymentProviderEnum = "",
  paymentMethod = ""
) {
  const [isCommissionLoading, setIsCommissionLoading] = useState(false);
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(false);
  let acquiringType;
  if (!isTip) {
    if (paymentType === AVAILABLE_PAYMENT_TYPES.GOOGLE_PAY) {
      acquiringType = googlePayPriorityArray[0]?.acquiringType;
    } else if (paymentType === AVAILABLE_PAYMENT_TYPES.APPLE_PAY) {
      acquiringType = applePriorityArray[0]?.acquiringType;
    } else if (paymentType == "card") {
      acquiringType = standardPriorityArray[0]?.acquiringType;
    } else if (paymentType === AVAILABLE_PAYMENT_TYPES.BINANCE_PAY) {
      acquiringType = "CITY_PAY_CRYPTO";
    } else if (paymentType == "installment") {
      acquiringType = "CREDO_INSTALLMENT";
    } else {
      acquiringType = "OPEN_BANKING";
    }
  } else if (isTip) {
    if (paymentMethod == "STANDARD") {
      if (paymentProviderEnum == "TBC") {
        acquiringType = "TBC_STANDARD";
      } else if (paymentProviderEnum == "CREDO") {
        acquiringType = "CREDO_STANDARD";
      } else {
        acquiringType = "BOG_STANDARD";
      }
    } else if (paymentMethod == "google") {
      if (paymentProviderEnum == "TBC") {
        acquiringType = "TBC_GOOGLE_PAY";
      } else if (paymentProviderEnum == "CREDO") {
        acquiringType = "CREDO_GOOGLE_PAY";
      } else {
        acquiringType = "BOG_GOOGLE_PAY";
      }
    } else if (paymentMethod == "apple") {
      if (paymentProviderEnum == "TBC") {
        acquiringType = "TBC_APPLE_PAY";
      } else if (paymentProviderEnum == "CREDO") {
        acquiringType = "CREDO_APPLE_PAY";
      } else {
        acquiringType = "BOG_APPLE_PAY";
      }
    } else if (paymentMethod == "OB") {
      acquiringType = "OPEN_BANKING";
    } else if (paymentMethod == "citypay") {
      acquiringType = "CITY_PAY_CRYPTO";
    }
  }

  useEffect(() => {
    if (!amount || !acquiringType) return;
    setIsCommissionLoading(true);

    const handler = setTimeout(async () => {
      const payload = {
        currency,
        userId: receiverId,
        userType: receiverType,
        acquiringType,
        isTip,
        amount,
        ...(integratorId && { integratorId }),
      };

      try {
        setIsCommissionLoading(true);
        const { senderCommission } = await getCommisionAmount(payload);
        setCommisionAmount(senderCommission);
        setIsCommissionLoading(false);

        if (currency == "GEL") {
          return;
        }
        if (
          paymentType != AVAILABLE_PAYMENT_TYPES.GOOGLE_PAY &&
          paymentType != AVAILABLE_PAYMENT_TYPES.APPLE_PAY &&
          !isTip
        ) {
          return;
        }
        setIsCurrencyLoading(true);
        convertCurrency(
          { amount, senderCommission },
          currency
          // acquiringCurrency
        )
          .then((data) => {
            setConvertedAmounts(data.amounts);
          })
          .finally(() => {
            setIsCurrencyLoading(false);
          });
      } catch (error) {
        setIsCommissionLoading(false);
        setIsCurrencyLoading(false);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [amount, currency, paymentType, acquiringType]);

  return {
    isCalculatingLoading: isCommissionLoading || isCurrencyLoading,
  };
}

export default useConvertedAmount;
