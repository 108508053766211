import {
  convertCurrency,
  getCommisionAmount,
  getCommisionAmountsEcomm,
} from "api";
import { AVAILABLE_PAYMENT_TYPES } from "features/bog/config";
import { SET_CONVERTED_AMOUNTS } from "features/bog/reducer/actions";
import { useState, useEffect } from "react";

function useConvertedAmountEcomm(
  // acquiringCurrency,
  amount = null,
  currency = "",
  receiverId = "",
  receiverType = "",
  standardPriorityArray = [],
  applePriorityArray = [],
  googlePayPriorityArray = [],
  isTip = false,
  integratorId = "",
  // setCommisionAmounts,
  setConvertedAmounts,
  paymentProviderEnum = "",
  paymentMethod = ""
) {
  const [isCommissionLoading, setIsCommissionLoading] = useState(false);
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(false);
  const [commisonAmounts, setCommisionAmounts] = useState({});

  const standardAcquiringType = standardPriorityArray[0]?.acquiringType;
  const googleAcquringType = googlePayPriorityArray[0]?.acquiringType;
  const appleAcquiringType = applePriorityArray[0]?.acquiringType;

  useEffect(() => {
    if (!amount) {
      return;
    }

    setIsCommissionLoading(true);

    const fn = async () => {
      const arr = [
        standardAcquiringType,
        googleAcquringType,
        appleAcquiringType,
        "CITY_PAY",
        // "CREDO_INSTALLMENT",
        "OPEN_BANKING",
      ];

      const filterdArr = arr.filter((item) => item != null);
      const payload = {
        currency,
        userId: receiverId,
        userType: receiverType,
        acquiringTypes: filterdArr,
        isTip,
        amount,
        ...(integratorId && { integratorId }),
      };

      try {
        setIsCommissionLoading(true);
        const { commissions } = await getCommisionAmountsEcomm(payload);
        setCommisionAmounts({
          appleCommision:
            commissions[applePriorityArray[0]?.acquiringType]
              ?.senderCommission || 0,
          googleCommision:
            commissions[googlePayPriorityArray[0]?.acquiringType]
              ?.senderCommission || 0,
          standardCommision:
            commissions[standardPriorityArray[0]?.acquiringType]
              ?.senderCommission || 0,
          cityPayCommision: commissions["CITY_PAY"]?.senderCommission || 0,
          openBankingCommision:
            commissions["OPEN_BANKING"]?.senderCommission || 0,
          // installmentCommision:
          //   commissions["CREDO_INSTALLMENT"].senderCommission,
        });
        setIsCommissionLoading(false);

        if (currency == "GEL") {
          return;
        }

        setIsCurrencyLoading(true);
        convertCurrency(
          {
            amount: amount,
            senderCommisionGoogle:
              commissions[googlePayPriorityArray[0]?.acquiringType]
                .senderCommission,
            senderCommisionApple:
              commissions[applePriorityArray[0]?.acquiringType]
                .senderCommission,
          },
          currency
          // acquiringCurrency
        )
          .then((data) => {
            setConvertedAmounts(data.amounts);
          })
          .finally(() => {
            setIsCurrencyLoading(false);
          });
      } catch (error) {
        setIsCommissionLoading(false);
        setIsCurrencyLoading(false);
      }
    };

    fn();
  }, [
    amount,
    currency,
    appleAcquiringType,
    standardAcquiringType,
    googleAcquringType,
    // acquiringCurrency,
  ]);

  return {
    isCalculatingLoading: isCommissionLoading || isCurrencyLoading,
    commisonAmounts: commisonAmounts,
  };
}

export default useConvertedAmountEcomm;
