export default function PaidIcon() {
  return (
    <svg
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30.6113" r="30" fill="#8CC63F" fill-opacity="0.1" />
      <path
        d="M16.5 30.7449L23.4338 39.2602C24.3342 40.3659 26.0229 40.3659 26.9233 39.2602L43.5 18.9028"
        stroke="#8CC63F"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
