import React, { forwardRef } from "react";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";

const CustomInput = forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      backgroundColor = "rgba(108, 99, 255, 0.1)",
      width = "350px",
      margin = "15px 0",
      borderRadius = "16px",
      name = "",
      type = "text",
      error = false,
      descError = false,
      disabled = false,
    },
    ref
  ) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();
    return (
      <div
        className={`${
          descError === false ? styles.customInput : styles.customInputError
        } ${!!value?.length && styles.hasValue}`}
        style={{
          width: width,
          margin: margin,
          borderRadius: borderRadius,
        }}
      >
        <input
          name={name}
          type={type}
          value={value}
          onChange={(e) => onChange(e.target)}
          style={{
            backgroundColor: backgroundColor,
            width: width,
            borderRadius: borderRadius,
          }}
          ref={ref}
          disabled={disabled}
        />
        <label>{placeholder}</label>
        {error && (
          <p className="text-red text-start text-[10px]">
            {t("payerPersonalNumberError")}
          </p>
        )}
      </div>
    );
  }
);

export default CustomInput;
