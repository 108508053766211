import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SWRConfig } from "swr";
import fetcher from "helpers/fetcher";
import App from "./App";
import "./index.css";
import "./i18n";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5KTGH5WG",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <StrictMode>
    <SWRConfig
      value={{
        fetcher,
        revalidateOnFocus: false,
        errorRetryCount: 0,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<App />}></Route>
        </Routes>
      </BrowserRouter>
    </SWRConfig>
  </StrictMode>
);
