import { useEffect, useMemo, useState } from "react";
import { paymentServiceInstance } from "api";
import { ceilToTwo } from "utils/functions";

export const useAcquringChoiceData = ({
  receiverId,
  receiverType,
  setAcquiringType,
}) => {
  // Fetch acquiring data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: { value: data },
        } = await paymentServiceInstance.get(
          `/api/v1/acquiring/choice/external?receiverId=${encodeURI(
            receiverId
          )}&receiverType=${encodeURI(receiverType)}`
        );

        setAcquiringType(data);
        return data;
      } catch (error) {
        console.log("error");
      }
    };

    fetchData();
  }, []);

  // const senderCommissionBool = commisonUsers || commisonMerchant;

  // Calculate commission data
  // const totalWithCommision = useMemo(() => {
  //   if (!senderCommissionBool || !amount) {
  //     return;
  //   }

  //   let comissionApple;
  //   let comissionRoundedApple;
  //   if (senderCommissionPercentApple?.rateType == "FIXED") {
  //     comissionApple = null;
  //     comissionRoundedApple = senderCommissionPercentApple?.senderCommission;
  //   } else {
  //     comissionApple =
  //       (amount * senderCommissionPercentApple?.senderCommission) / 100;
  //     comissionRoundedApple =
  //       Math.ceil((comissionApple * 100).toFixed(2)) / 100;
  //   }

  //   let comissionGoogle;
  //   let comissionRoundedGoogle;
  //   if (senderCommissionPercentGoogle?.rateType == "FIXED") {
  //     comissionGoogle = null;
  //     comissionRoundedGoogle = senderCommissionPercentGoogle?.senderCommission;
  //   } else {
  //     comissionGoogle =
  //       (amount * senderCommissionPercentGoogle?.senderCommission) / 100;
  //     comissionRoundedGoogle =
  //       Math.ceil((comissionGoogle * 100).toFixed(2)) / 100;
  //   }

  //   let comissionStandard;
  //   let comissionRoundedStandard;
  //   if (senderCommissionPercentStandard?.rateType == "FIXED") {
  //     comissionStandard = null;
  //     comissionRoundedStandard =
  //       senderCommissionPercentStandard?.senderCommission;
  //   } else {
  //     comissionStandard =
  //       (amount * senderCommissionPercentStandard?.senderCommission) / 100;
  //     comissionRoundedStandard = ceilToTwo(comissionStandard);
  //   }

  //   let comissionCrypto;
  //   let comissionRoundedCrypto;
  //   if (senderCommissionPercentCrypto?.rateType == "FIXED") {
  //     comissionCrypto = null;
  //     comissionRoundedCrypto = senderCommissionPercentCrypto?.senderCommission;
  //   } else {
  //     comissionCrypto =
  //       (amount * senderCommissionPercentCrypto?.senderCommission) / 100;
  //     comissionRoundedCrypto =
  //       Math.ceil((comissionCrypto * 100).toFixed(2)) / 100;
  //   }

  //   let comissionOB;
  //   let comissionRoundedOB;
  //   if (senderCommissionPercentOB?.rateType == "FIXED") {
  //     comissionOB = null;
  //     comissionRoundedOB = senderCommissionPercentOB?.senderCommission;
  //   } else {
  //     comissionOB =
  //       (amount * senderCommissionPercentOB?.senderCommission) / 100;
  //     comissionRoundedOB = Math.ceil((comissionOB * 100).toFixed(2)) / 100;
  //   }

  //   let comissionInsatllment;
  //   let comissionRoundedInstallment;
  //   if (senderCommissionPercentInstallmet?.rateType == "FIXED") {
  //     comissionInsatllment = null;
  //     comissionRoundedInstallment =
  //       senderCommissionPercentInstallmet?.senderCommission;
  //   } else {
  //     comissionInsatllment =
  //       (amount * senderCommissionPercentInstallmet?.senderCommission) / 100;
  //     comissionRoundedInstallment =
  //       Math.ceil((comissionInsatllment * 100).toFixed(2)) / 100;
  //   }

  //   return {
  //     totalApple: (Number(amount) + Number(comissionRoundedApple)).toFixed(2),
  //     comissionApple: parseFloat(comissionRoundedApple),
  //     totalGoogle: (Number(amount) + Number(comissionRoundedGoogle)).toFixed(2),
  //     comissionGoogle: parseFloat(comissionRoundedGoogle),
  //     totalStandard: (
  //       Number(amount) + Number(comissionRoundedStandard)
  //     ).toFixed(2),
  //     comissionStandard: parseFloat(comissionRoundedStandard),
  //     totalCrypto: (Number(amount) + Number(comissionRoundedCrypto)).toFixed(2),
  //     comissionCrypto: parseFloat(comissionRoundedCrypto),
  //     totalOB: (Number(amount) + Number(comissionRoundedOB)).toFixed(2),
  //     comissionOB: parseFloat(comissionRoundedOB),
  //     totalInsallment: (
  //       Number(amount) + Number(comissionRoundedInstallment)
  //     ).toFixed(2),
  //     comissionInsatllment: parseFloat(comissionRoundedInstallment),
  //   };
  // }, [
  //   amount,
  //   senderCommissionPercentApple,
  //   senderCommissionPercentCrypto,
  //   senderCommissionPercentGoogle,
  //   senderCommissionPercentStandard,
  //   senderCommissionPercentOB,
  //   senderCommissionPercentInstallmet,
  //   senderCommissionBool,
  // ]);

  // return {
  //   totalWithCommision,
  //   senderCommissionBool,
  // };
};
