import { useEffect } from "react";
import apple from "assets/images/icon/appstoreupdated.svg";
import googleIcon from "assets/images/icon/googleplayupdated.svg";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import TransactionCompleteAnim from "../../utils/pendincAnimation.json";
import { threeDsCredoFinish, threeDsTbcFinish } from "api";
import { useNavigate } from "react-router-dom";

const ThreeDsPending = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const credoId = localStorage.getItem("3dsCredoId");
    const tbcId = localStorage.getItem("3dsTbcId");
    if (credoId) {
      threeDsCredoFinish(credoId)
        .then(
          ({
            id,
            status,
            ratingEnabled,
            tipsEnabled,
            receiverName,
            receiverId,
            beneficiaryType,
            acquiringTransactionId,
            paymentId,
            integratorOrderId,
            genericId,
            amount,
            currency,
            showAwardedGiftComponent = "false",
          }) => {
            if (status == "AUTHENTICATED") {
              const redirectURL = `/success?acquiringTransactionId=${acquiringTransactionId}&receiverType=${beneficiaryType}&amount=${amount}&receiverName=${encodeURIComponent(
                receiverName
              )}&ratingEnabled=${!!ratingEnabled}&tipsEnabled=${!!tipsEnabled}&receiverId=${receiverId}&paymentProviderEnum=CREDO&paymentMethod=google&genericId=${genericId}&integratorOrderId=${integratorOrderId}&currency=${currency}&showAwardedGiftComponent=${showAwardedGiftComponent}`;
              navigate(redirectURL);
            }
          }
        )
        .catch(({ response: { data: err } }) => {
          console.log(err, "errr");
          navigate(
            `/payment-failed?statusCode=${err?.statusCode}&exceptionGroup=${err?.exceptionGroup}`
          );
        });
    } else if (tbcId) {
      threeDsTbcFinish(tbcId)
        .then(
          ({
            id,
            status,
            ratingEnabled,
            tipsEnabled,
            receiverName,
            receiverId,
            beneficiaryType,
            acquiringTransactionId,
            paymentId,
            integratorOrderId,
            genericId,
            amount,
            currency,
            showAwardedGiftComponent = "false",
          }) => {
            if (status == "AUTHENTICATED") {
              const redirectURL = `/success?acquiringTransactionId=${acquiringTransactionId}&receiverType=${beneficiaryType}&amount=${amount}&receiverName=${encodeURIComponent(
                receiverName
              )}&ratingEnabled=${!!ratingEnabled}&tipsEnabled=${!!tipsEnabled}&receiverId=${receiverId}&paymentProviderEnum=TBC&paymentMethod=google&genericId=${genericId}&integratorOrderId=${integratorOrderId}&currency=${currency}&showAwardedGiftComponent=${showAwardedGiftComponent}`;
              navigate(redirectURL);
            }
          }
        )
        .catch(({ response: { data: err } }) => {
          console.log(err, "errr");
          navigate(
            `/payment-failed?statusCode=${err?.statusCode}&exceptionGroup=${err?.exceptionGroup}`
          );
        });
    }

    return () => {
      localStorage.removeItem("3dsCredoId");
      localStorage.removeItem("3dsTbcId");
    };
  }, []);

  return (
    <div className="w-[100%] h-[100%] flex justify-around items-center flex-col relative">
      <div className="flex justify-center items-center flex-col gap-1  ">
        <div className="h-[100px] w-[100px] bg-[#FFA500] rounded-[50%] flex justify-center items-center">
          <Lottie
            animationData={TransactionCompleteAnim}
            style={{ width: "55px", height: "55px" }}
          />
        </div>
        <p className="text-[20px] text-[#170738] mt-[20px]">
          Payment is processing
        </p>
        <div style={{ transform: "translateY(-30px)", marginTop: "20px" }}>
          <p className="opacity-50 text-center whitespace-pre-line">
            It may take a few seconds
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center  ">
        <div className="flex items-center justify-center gap-[10px]">
          <a
            href="https://apps.apple.com/us/app/tip-app-mobile/id1579469134"
            target="_blank"
            rel="noreferrer"
          >
            <img src={apple} alt="#" width="158px" height="52px" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.tipapp"
            target="_blank"
            rel="noreferrer"
          >
            <img src={googleIcon} alt="#" width="158px" height="52px" />
          </a>
        </div>
        <div className="flex items-center justify-center w-full pb-10 self-end p-4">
          <button
            className="max-w-2xl  w-full inline-flex justify-center gap-2 p-4 text-[20px] text-[#6C63FF] font-[600] mt-4 rounded-2xl disabled:bg-gray-400 text-center items-center"
            style={{ height: "50px", borderRadius: "8px" }}
            onClick={() => {
              const currentURL = window.location.href;
              const url = new URL(currentURL);
              const newURL = `${url.origin}`;
              window.location.href = newURL;
            }}
          >
            {t("description.done")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThreeDsPending;
