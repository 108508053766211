import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

export const SkiPassPage = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("ge");
  }, []);

  return (
    <div className="sm:px-[20px] md:px-0">
      <h1
        className="font-bold text-[34px] mt-[80px] "
        style={{ fontVariant: "all-petite-caps", lineHeight: "30px" }}
      >
        <Trans i18nKey={"campaign.winSkipass"} />
      </h1>
      <div className="mt-[20px]">
        <p style={{ color: "#777987", fontSize: "16px" }}>
          <Trans i18nKey={"campaign.rules"} />
        </p>
        <ul
          style={{
            listStyleType: "disc",
            listStylePosition: "inside",
            marginTop: "15px",
          }}
        >
          <li style={{ color: "#777987", fontSize: "16px" }}>
            {" "}
            <Trans i18nKey={"campaign.firstBullet"} />
          </li>
          <li style={{ color: "#777987", fontSize: "16px" }}>
            {" "}
            <Trans i18nKey={"campaign.secondBullet"} />
          </li>
          <li style={{ color: "#777987", fontSize: "16px" }}>
            {" "}
            <Trans i18nKey={"campaign.thirdBullet"} />
          </li>
          <li style={{ color: "#777987", fontSize: "16px" }}>
            {" "}
            <Trans i18nKey={"campaign.fourthBullet"} />
          </li>
        </ul>
        <p className="mt-[8px]" style={{ color: "#777987", fontSize: "16px" }}>
          {" "}
          <Trans i18nKey={"campaign.wishULuck"} />
        </p>
        <p className="mt-[8px]" style={{ color: "#777987", fontSize: "16px" }}>
          <Trans i18nKey={"campaign.fir"} />
        </p>
      </div>
      <h1
        className="font-bold text-[34px] mt-[80px] "
        style={{ fontVariant: "all-petite-caps", lineHeight: "30px" }}
      >
        <Trans i18nKey={"campaign.storesToWin"} />
      </h1>
      <div className="mt-[40px]">
        <h2
          className="text-[27px] text-[#6C63FF] font-bold"
          style={{ fontVariant: "all-petite-caps" }}
        >
          <Trans i18nKey={"campaign.gudauri"} />
        </h2>
        <div className=" md:gap-[10px] flex flex-wrap">
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop1"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop1Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598928055"
            >
              598928055
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop2"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop2Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599902046"
            >
              599902046
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop3"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop3Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598909082"
            >
              598909082
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop4"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop4Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+592712471"
            >
              592712471
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop5"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop5Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+511286018"
            >
              511286018
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop6"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop6Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+577327058"
            >
              577327058
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop7"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop7Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+591288803"
            >
              591288803
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop8"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop8Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+571054051"
            >
              571054051
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop9"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop9Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598582006"
            >
              598582006
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop10"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop10Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+568707777"
            >
              568707777
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop11"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop11Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+557737109"
            >
              557737109
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop12"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop12Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+598149144"
            >
              598149144
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop13"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop13Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551872287"
            >
              551872287
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop14"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop14Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+579909834"
            >
              579909834
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop15"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop15Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+593444388"
            >
              593444388
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop16"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop16Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+557671228"
            >
              557671228
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop17"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop17Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+574251759"
            >
              574251759
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop18"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop18Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599463326"
            >
              599463326
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop19"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop19Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+514500700"
            >
              514500700
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop20"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop20Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+597785741"
            >
              597785741
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop21"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop21Location"} />
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+597785741"
            >
              591907876
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop22"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop22Location"} />
            </p>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">
              <Trans i18nKey={"campaign.shop23"} />
            </p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              <Trans i18nKey={"campaign.shop23Location"} />
            </p>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">ვასილი ზაქაიძე</p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+551700066"
            >
              551700066
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">ირინა აბრამიძე</p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599939005"
            >
              599939005
            </a>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">Ski Line Gudauri</p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              ახალი გუდაური
            </p>
            <span className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              TEL:
            </span>{" "}
            <a
              className="text-[14px] text-[#170738] mt-[10px] opacity-50"
              href="tel:+599989962"
            >
              599989962
            </a>
          </div>
        </div>
        {/* <h2
          className="text-[27px] mt-[20px] text-[#6C63FF] font-bold"
          style={{ fontVariant: "all-petite-caps" }}
        >
          ბაკურიანი
        </h2> */}
        {/* <div className="md:gap-[10px] flex flex-wrap">
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">მაღაზია ნიკორა</p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              დავით აღმაშენებლის ქუჩა #15, გუდაური
            </p>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">მაღაზია ნიკორა</p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              დავით აღმაშენებლის ქუჩა #15, გუდაური
            </p>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">მაღაზია ნიკორა</p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              დავით აღმაშენებლის ქუჩა #15, გუდაური
            </p>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">მაღაზია ნიკორა</p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              დავით აღმაშენებლის ქუჩა #15, გუდაური
            </p>
          </div>
          <div className="w-[100%] mt-[20px] md:w-[400px] rounded-[12px] border-[1px] bg-[#6C63FF0D] border-[#6C63FF33] p-[20px]">
            <p className="text-[16px] font-bold">მაღაზია ნიკორა</p>
            <p className="text-[14px] text-[#170738] mt-[10px] opacity-50 ">
              დავით აღმაშენებლის ქუჩა #15, გუდაური
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SkiPassPage;
